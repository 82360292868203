import React, { useState, useEffect } from 'react';
import plusIcon from "./../../../../src/images/LeadingIcon.svg"
import searchIcon from './../../../../src/images/search-lg.svg';
import'./../../../../src/styles/common.css'
import deleteIcon from './../../../../src/images/trash-01.svg';
import editIcon from './../../../../src/images/edit-01.svg'
import ApiService from '../../../Services/ApiService';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, MenuItem,TableSortLabel, TablePagination, FormControl,InputLabel,Select } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons'; // Import icons from Material-UI
import ContentPageAdd from './ContentPageAdd';
import ContentPageEdit from './ContentPageEdit';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DialogBox } from '../../Dialog Box';
import { Loader } from '../../Loader';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import EditContentDialog from "./EditContentDialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const ContentPageList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUserList, setShowUserList] = useState("UserList");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [editData, seteditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [languageList, setLanguageList] = useState([])


  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);
  const [languageValues,setlanguageValues]=useState('en')
 

  const [dialogData, setDialogData] = useState(); 
  const [filterData, setFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: '',
    OrderByField: '',
    Sort: '',
    Aggregator: 'OR',
    Conditions: [
      {
        TableName: 'SdMContentpages',
        FieldName: 'ContentTitle',
        Operator: 'like',
        FieldValue: ''
      },
      {
        TableName: 'SdMContentpages',
        FieldName: 'ImageUrl',
        Operator: 'like',
        FieldValue: ''
      },
      
    ]
  });
  const [languageFilterData, setLanguageFilterData] = useState({
    Index: 0,
    Limit: 15,
    OrderByTable: '',
    OrderByField: 'Priority',
    Sort: '',
    Aggregator: 'OR',
    Conditions: [
      {
        TableName: 'SdMLanguage',
        FieldName: 'LanguageName',
        Operator: 'like',
        FieldValue: ''
      },
      
    ]
  });
  const [dataSource, setDataSource] = useState([]);
  const [mobileJsonData, setMobileJsonData] = useState([]);
  const [dataSourceRes, setDataSourceRes] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [value, setValue] = useState(0);
  const [editvalue, setEditValue] = useState(null);
  const [EditDialogOpen, setEditDialogOpen] = useState(false);
  const [ContentEditData, setContentEditData] = useState(null);
  const [editKey, setEditKey] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#EE3823",
    },
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("ddd", event, newPage, page);
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Index: newPage * rowsPerPage,
    }));
    
  };
  const EditClose = () => {
    setEditDialogOpen(false);
    getMobileJsonContent(languageValues)
  };
  const [userData, setUserData] = useState(null);
  const [mobileJsonRes, setMobileJsonRes] = useState(null);

  useEffect(() => {
    
    const storedData = sessionStorage.getItem('userDetail');

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
    getMobileJsonContent("en")
    getLanguageList()
    
  }, []);
  const LanguagehandleChange = (e) => {
    const { name, value } = e.target;
   setlanguageValues(value)
   getMobileJsonContent(value);

  };
  useEffect(() => {
  
    fetchData();
    setDeleteData(false)
  }, [filterData.Index,showUserList,deleteData]);
  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset page to 0 when rows per page changes
    setFilterData((prevFilterData) => ({
      ...prevFilterData,
      Limit: newRowsPerPage, // Update Limit based on the new rows per page
    }));
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogData(false)

    setDialogOpen(false);
  };
const DeleteClose=()=>{
  setdeleteDialogOpen(false)
}

const getLanguageList = async (language) => {
  try {
    const res = await ApiService.post("sdmlanguage/list", languageFilterData);
    if (res) {
      // setcontentList(res);
      // setContentData(res)
      setLanguageList(res.SdMLanguage)
    }
  } catch (error) {
    console.error("Error updating toggle state:", error);
  }
};
  const handleDialogConfirm = () => {
    setDialogData(true)
    updateToggleState()
    handleDialogClose(); 
  };
  const [userDates, setUserDates] = useState({
    Id: null,
    UserId: null,
    IsActive: false
  });
  const [deleteId, setdeleteId]=useState()
  const onSlideToggleChange = async (id, event) => {
    const checkeds = event.target.checked;
    try {
      setUserDates({
        ...userDates,
        Id: id,
        UserId: userData.Id,
        IsActive: checkeds
      });
        handleDialogOpen(userDates);
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  const deleteUser = async (id) => {
    try {
      setdeleteDialogOpen(true);
      setdeleteId(id)
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  
  const updateToggleState = async () => {
    try {
      const res = await ApiService.isActiveput("sdmcontentpages/isactive", userDates);
      if (res) {
        const updatedDataSource = dataSource.map(data => {
          if (data.Id === userDates.Id) {
            return { ...data, IsActive: userDates.IsActive };
          }
          return data;
        });
        setDataSource(updatedDataSource);
    enqueueSnackbar('Status Updated Successfully!',{ variant: 'success' });
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  };
  useEffect(() => {
   
  }, []);

  const fetchData = async () => {
    setLoading(true); // Set loading state to true before fetching data
    try {
      const response = await ApiService.getAll("sdmcontentpages/getpagemaster");
      if (response && response) {
        setDataSource(response);
        setDataSourceRes(response)
        setTotalRecords(response.count);
        setCount(response.count);
      }
      setData(response.data);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading state to false after fetching data
    }
  };
  const getMobileJsonContent = async (language) => {
    let ress = {
      key: 'value'
  };
    try {
      const res = await ApiService.getAll(
        "sdmcontentpages/getmobilejsoncontent",language
      );
      if (res) {
        setMobileJsonData(res);
        setMobileJsonRes(res)
        // setContentData(res)
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const handleCancel = () => {
    setShowUserList("UserList");
  };
  const handleKeyUp = async (event) => {
    console.log(value);
    const searchQuery = event.target.value.toLowerCase();
    setSearch(searchQuery);
   
    if(value == 1){
      const updatedConditions = Object.fromEntries(
        Object.entries(mobileJsonRes).filter(([key, value]) => value.toLowerCase().includes(searchQuery))
    );
    setMobileJsonData(updatedConditions)
    }
    if(value == 0){
    const updatedConditions = dataSourceRes.filter(page => page.PageName.toLowerCase().includes(searchQuery));
    setDataSource(updatedConditions)
    }
   

    // Update filterData state with the updated conditions
    // setFilterData({ ...filterData, Conditions: updatedConditions });
    // console.log(filterData,updatedConditions,filterData.Conditions);
    // await fetchData()
   
  };
  useEffect(() => {
  
    fetchData(); // Call fetchData initially, and every time filterData changes
    
  }, [filterData.Conditions,filterData]); 
  const handleSort = (column) => {
    console.log('cccc',column);
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
      setFilterData(prevFilterData => ({
        ...prevFilterData,
        OrderByField: column,
        Sort: sortOrder,
        OrderByTable: 'SdMContentpages'
      }));
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
   
  };
  const renderSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === 'asc' ? <ArrowUpward className='sortIcon'/> : <ArrowDownward className='sortIcon' />;
    }
    return null;
  };
  function handleEditClick(data){
    setShowUserList("UserEdit")
    seteditData(data)
  }
  const handleDeleteClick =async () =>{
    try {
      const res = await ApiService.delete("sdmcontentpages",deleteId );
      if (res) {
        setDeleteData(true)
        setdeleteDialogOpen(false)
    enqueueSnackbar('Deleted Successfully!',{ variant: 'success' });
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleContentEditClick = (item,key,value) => {
    console.log(item);
    setContentEditData(item);
    setEditKey(key)
    setEditValue(value)
    console.log(key);
    setEditDialogOpen(true);
  };
  return (
    <div>
      <EditContentDialog
        // title={"Confirmation"}
        // content={"Are you sure want to delete this list?"}
        open={EditDialogOpen}
        onClose={EditClose}
        type={"mobile"}
        // onConfirm={handleDeleteClick}
        // onFormSubmit={updateValue} // Pass the callback function
        EditData={ContentEditData}
        value={editvalue}
        keyValue={editKey}
        pageKey={dataSource.PageKey}
        languageValues={languageValues}
      />
      {/* Dialog component */}
      {/* <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to activate this user?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
     <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to active/inactive this list?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to delete this list?"}
        open={deleteDialogOpen}
        onClose={DeleteClose}
        onConfirm={handleDeleteClick}
      />
      {loading && <Loader />}
    {showUserList === 'UserList' ? (
      <div>
      <div className="addUser">
      <p className="indicatorText">Content Pages</p>
        <div className="SearchBtn">
            <img src={searchIcon} alt="" />
        <form >
            <input type="text" placeholder="Search Content Pages List" value={search} onChange={e => setSearch(e.target.value)}
             onKeyUp={handleKeyUp}/>
        </form>
          </div>
          {/* <Button mat-button className="addUserBtn"  onClick={() => setShowUserList("UserAdd")}>
            <div className="button-content">
              <img src= {plusIcon} alt="" />
              <span>Add Content Pages</span>
            </div>
          </Button> */}
    </div>
    <Box sx={{ width: "100%" }} className="Ptabs">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              indicatorColor="secondary"
              value={value}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#EE3823",
                  color: "#EE3823",
                  height: 3,
                  marginRight: 60,
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Webapp Content" {...a11yProps(0)} />
              <StyledTab label="Mobile App Content" {...a11yProps(1)} />
             
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
          <div className='tableContainer' style={{ marginTop: '2%' }}>
    {/* <TableCompontent data={userData}/> */}
    <Paper className="wholeTable"> {/* Use Paper as the container */}
      <Table>
        <TableHead>
          {/* Table header */}
          <TableRow className="tr">
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('ContentTitle')} className="tableHead">Page Name
            {renderSortIcon('ContentTitle')}</TableCell>
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('ContentTitle')} className="tableHead">Page Key
            {renderSortIcon('ContentTitle')}</TableCell>
            {/* <TableCell style={{ width: '20%' }} className="tableHead">URL</TableCell>
            <TableCell style={{ width: '20%' }} className="tableHead">Template</TableCell>
            <TableCell style={{ width: '15%' }} className="tableHead">Status</TableCell> */}
            <TableCell style={{ width: '15%' }} className="tableHead">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {dataSource.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
    dataSource.map((dataSource, index) => (
      <TableRow key={index}>
        <TableCell align="left" style={{ width: '30%' }} className="tableBody">{dataSource.PageName} {dataSource.Status === 'pendingReview' && <span className='colStatus'>
          Pending Review
          </span>}</TableCell>
          <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.PageKey }</TableCell>
        {/* <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.ImageUrl ? dataSource.ImageUrl : "-"}</TableCell>
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{dataSource.TemplateName}</TableCell>
      <TableCell align="left" style={{ width: '15%' }}>
      <label className="switch">
<input
  className="switch-input"
  type="checkbox"
  checked={dataSource.IsActive}
  onChange={(event) => onSlideToggleChange(dataSource.Id, event)}
/>
<span className="switch-label" data-on="Active" data-off="Inactive"></span>
<span className="switch-handle"></span>
</label>
      </TableCell> */}
      <TableCell align="left" style={{ width: '15%' }}>
      <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleEditClick(dataSource) } />
      {/* <img src= {deleteIcon} alt="" className='action cursor' onClick={() => deleteUser(dataSource.Id) } /> */}
      </TableCell>
      </TableRow>
    ))
  )}
         
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[15,25,50,75,100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      /> */}
    </Paper>
    </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
          <div className='tableContainer mt-[30px]' style={{ marginTop: '2%',}}>
    {/* <TableCompontent data={userData}/> */}
    <FormControl>
                  <InputLabel>Language</InputLabel>
                  <Select
                    name="Language"
                    value={languageValues}
                    onChange={LanguagehandleChange}
                    required
                    className="commonInput"
                  >
                    {languageList.map((status) => (
                      <MenuItem key={status.LanguageShortName} value={status.LanguageShortName}>
                        {status.LanguageName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
    <Paper className="wholeTable" style={{ marginTop: '2%',}}> {/* Use Paper as the container */}
      <Table>
        <TableHead>
          {/* Table header */}
          <TableRow className="tr">
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('ContentTitle')} className="tableHead"> Key
            {renderSortIcon('ContentTitle')}</TableCell>
            <TableCell style={{ width: '30%' }} onClick={() => handleSort('ContentTitle')} className="tableHead"> Value
            {renderSortIcon('ContentTitle')}</TableCell>
            {/* <TableCell style={{ width: '20%' }} className="tableHead">URL</TableCell>
            <TableCell style={{ width: '20%' }} className="tableHead">Template</TableCell>
            <TableCell style={{ width: '15%' }} className="tableHead">Status</TableCell> */}
            <TableCell style={{ width: '15%' }} className="tableHead">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {dataSource.length === 0 ? (
    <TableRow>
      <TableCell colSpan={6} align="center" style={{ textAlign: "center", verticalAlign: "middle" }}>
        No data available
      </TableCell>
    </TableRow>
  ) : (
    Object.entries(mobileJsonData).map(([key, value], index) => (
      <TableRow key={index}>
        {/* <TableCell align="left" style={{ width: '30%' }} className="tableBody">{dataSource.ContentTitle} {dataSource.Status === 'pendingReview' && <span className='colStatus'>
          Pending Review
          </span>}</TableCell> */}
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{key}</TableCell>
        <TableCell align="left" style={{ width: '20%' }} className="tableBody">{value.slice(0, 100)}</TableCell>
    
      <TableCell align="left" style={{ width: '15%' }}>
      <img src= {editIcon} alt=""  className='action cursor' onClick={() => handleContentEditClick(mobileJsonData,key,value)}  />
      {/* <img src= {deleteIcon} alt="" className='action cursor' onClick={() => deleteUser(dataSource.Id) } /> */}
      </TableCell>
      </TableRow>
    ))
  )}
         
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[15,25,50,75,100]}
        component="div"
        // count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      /> */}
    </Paper>
    </div>
    </TabPanel>
       
        
       
        </Box>

    
    </div>
    ) : showUserList === "UserAdd" ? (<ContentPageAdd onCancel={handleCancel}  />) :(<ContentPageEdit onCancel={handleCancel} dataSource={editData} />)}    </div>
  );
};

export default ContentPageList;
