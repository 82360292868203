import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import SidebarItemStyles from '../../styles/SidebarItem';
import { capitalize, capitalizeEveryWord } from '../../helpers';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

class SidebarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: null
    };
  }

  componentDidMount() {
    const storedData = sessionStorage.getItem('userDetail');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.setState({ userData: parsedData }, () => {
        console.log('userData:', this.state.userData);
      });
    }

    if (!this.props.currentPath || this.props.activeRoute === this.props.index || this.props.route.path === '/') return;
    this.props.toggleMenu(this.props.index)
  }

  render() {
    const { classes, route, index, activeRoute, toggleMenu } = this.props;
    const { userData } = this.state;
    const badge = (badge) => {
      if (!badge) return;
      const badgeClassName = classNames(classes.badge, {
        [classes[`${badge.type}`]]: badge.type !== 'default',
      });
      return <Typography className={classNames(classes.badge, badgeClassName)} component="div">{badge.value}</Typography>;
    }

    if (route.type === 'external') {
      return (
        <a href={route.path} target="_blank" key={index} className={classes.menuLink}>
          <ListItem className={classes.menuItem} button>
            {/* <ListItemIcon>
              <route.icon className={classes.menuIcon} />
            </ListItemIcon> */}
            <Typography variant="body1" className="flexSpacer">{capitalizeEveryWord(route.name)}</Typography>
            {badge(route.badge)}
          </ListItem>
        </a>
      );
    }

    // if (route.type === 'submenu') {
    //   return (
    //     <div className={activeRoute === index ? classes.menuCollapsed : classes.menuClosed}>
    //       <ListItem className={`${classes.menuItem} liststyle`} button key={index} onClick={() => toggleMenu(index)}>
    //         {/* <ListItemIcon>
    //           <route.icon className={classes.menuIcon} />
    //         </ListItemIcon> */}
    //         <Typography variant="body1" className="flexSpacer">{capitalize(route.name)}</Typography>
    //         {badge(route.badge)}
    //         <ListItemIcon className={classes.caret} >
    //           {activeRoute === index ? <ArrowDropUpIcon className='dropDownIcon' /> : <ArrowDropDownIcon className='dropDownIcon' />}
    //         </ListItemIcon>
    //       </ListItem>
    //       <Collapse in={activeRoute === index ? true : false} timeout="auto" unmountOnExit>
    //       <div style={{ backgroundColor: 'transparent' }}>
    //       <List disablePadding >
    //           {route.children.map((subitem, index) => (
    //               <NavLink to={`${route.path ? route.path : ''}${subitem.path ? subitem.path : ''}`} exact className={classes.menuLink} activeClassName={classes.menuActive} key={index}>
    //                 <ListItem className={classes.menuSubItem} button>
    //                   <Typography variant="body1" className="flexSpacer">{capitalize(subitem.name)}</Typography>
    //                   {badge(subitem.badge)}
    //                   {
    //                     subitem.superchildBoolen ? 
    //                     <ListItemIcon className={classes.caret} >
    //                   {activeRoute === index ? <ArrowDropUpIcon className='dropDownIcon' /> : <ArrowDropDownIcon className='dropDownIcon' />}
    //               </ListItemIcon> :null
    //                   }
                      
    //                 </ListItem>
    //               </NavLink>)
    //           )}
    //         </List>
    //       </div>
            
    //       </Collapse>
    //     </div>
    //   )
    // }


    if (route.type === 'submenu') {
  return (
    <div className={activeRoute === index ? classes.menuCollapsed : classes.menuClosed}>
      <ListItem className={`${classes.menuItem} liststyle`} button key={index} onClick={() => toggleMenu(index)}>
        <Typography variant="body1" className="flexSpacer tit">{capitalizeEveryWord(route.name)}</Typography>
        {badge(route.badge)}
        <ListItemIcon  >
          {activeRoute === index ? <KeyboardArrowUpIcon className='dropDownIcon' /> : <KeyboardArrowDownIcon className='dropDownIcon' />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={activeRoute === index ? true : false} timeout="auto" unmountOnExit>
        <div style={{ backgroundColor: 'transparent' }}>
          <List disablePadding >
            {route.children.map((subitem, subindex) => (
              <NavLink to={subitem.name != 'ACCOUNT' && `${route.path ? route.path : ''}${subitem.path ? subitem.path : ''}`} exact className={classes.menuLink} activeClassName={classes.menuActive} key={subindex}>
                
                {(userData && userData.RoleId != 1) ? (subitem.name !== 'ACCOUNT' && 
                 <ListItem className='submenuCls'  onClick={() => {if (subitem.name === 'ACCOUNT') {subitem.superchildBoolen = !subitem.superchildBoolen;  }}} button>
                 <Typography variant="body1"  className={subitem.name === 'ACCOUNT' ? 'flexSpacer tit' : 'flexSpacer tit12'}>{capitalizeEveryWord(subitem.name)}</Typography>
                 {badge(subitem.badge)}
                 {
                    subitem.superChild?
                     <ListItemIcon >
                       {subitem.superchildBoolen  ? < KeyboardArrowUpIcon className='dropDownIcon' /> : <KeyboardArrowDownIcon className='dropDownIcon' />}
                     </ListItemIcon> : null
                 }

               </ListItem>): (<ListItem className='submenuCls'  onClick={() => {if (subitem.name === 'ACCOUNT') {subitem.superchildBoolen = !subitem.superchildBoolen;  }}} button>
                 <Typography variant="body1"  className={subitem.name === 'ACCOUNT' ? 'flexSpacer tit' : 'flexSpacer tit12'}>{capitalizeEveryWord(subitem.name)}</Typography>
                 {badge(subitem.badge)}
                 {
                    subitem.superChild?
                     <ListItemIcon >
                       {subitem.superchildBoolen  ? < KeyboardArrowUpIcon className='dropDownIcon' /> : <KeyboardArrowDownIcon className='dropDownIcon' />}
                     </ListItemIcon> : null
                 }

               </ListItem>)}
                {/* <ListItem className='submenuCls'  onClick={() => {if (subitem.name === 'ACCOUNT') {subitem.superchildBoolen = !subitem.superchildBoolen;  }}} button>
                 <Typography variant="body1"  className={subitem.name === 'ACCOUNT' ? 'flexSpacer tit' : 'flexSpacer tit12'}>{capitalize(subitem.name)}</Typography>
                 {badge(subitem.badge)}
                 {
                    subitem.superChild?
                     <ListItemIcon >
                       {subitem.superchildBoolen  ? < ArrowDropUpIcon className='dropDownIcon' /> : <ArrowDropDownIcon className='dropDownIcon' />}
                     </ListItemIcon> : null
                 }

               </ListItem> */}
               
              </NavLink>)
            )}
          {(userData && userData.RoleId === 1) ? (
  route.children.find(subitem => subitem.name === 'ACCOUNT' && subitem.superchildBoolen) ? (
    <List disablePadding>
      {route.children.find(subitem => subitem.name === 'ACCOUNT').superChild.map((superChildItem, superChildIndex) => (
        <NavLink to={`${route.path ? route.path : ''}${superChildItem.path ? superChildItem.path : ''}`} exact className={classes.menuLink} activeClassName={classes.menuActive} key={superChildIndex}>
          <ListItem className={classes.menuSubItem} button>
            <Typography variant="body1" className="flexSpacer tit12">{capitalizeEveryWord(superChildItem.name)}</Typography>
            {badge(superChildItem.badge)}
          </ListItem>
        </NavLink>
      ))}
    </List>
  ) : null
) : null}
            
          </List>
        </div>
      </Collapse>
    </div>
  )
}

    return (
      <NavLink to={route.path} exact className={classes.menuLink} activeClassName={classes.menuActive} key={index}>
        <ListItem className={classes.menuItem} button onClick={() => toggleMenu(index)}>
          {/* <ListItemIcon>
            <route.icon className={classes.menuIcon} />
          </ListItemIcon> */}
          <Typography variant="body1" className="flexSpacer">{capitalizeEveryWord(route.name)}</Typography>
          {badge(route.badge)}
        </ListItem>
      </NavLink>
    )
  }
}

SidebarItem.prototypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.object,
  index: PropTypes.number,
  activeRoute: PropTypes.number,
  toggleMenu: PropTypes.func
};

export default withStyles(SidebarItemStyles)(SidebarItem);