import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";
import Checkbox from "@mui/material/Checkbox";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import ApiService from "../../../Services/ApiService";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Loader } from "../../Loader";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

const BoquetPageEdit = ({ onCancel, dataSource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    BouquetName: "",
    SystemName: "",
    Poid: 0,
    Description: "",
    BouquetSmsCode: "",
    Price: "",
    IsSdBouquet: true,
    ShowInFTA:false,
    IsBcBouquet: false,
  });
  const [loading, setLoading] = useState();
  const [Networkloading, setNetworkLoading] = useState();
  const formRef = useRef(null);
  const [selectedNetworkChannels, setSelectedNetworkChannels] = useState({});
  const [selectedOption, setSelectedOption] = useState("Sundirect");
  const [isActive, setIsActive] = useState("Active");
  const [BouquetStatus, setBouquetStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();
  const [ApprovedOn, setApprovedOn] = useState(false);
  const [ApprovedBy, setApprovedBy] = useState();
  const [IsPublished, setIsPublished] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [ListArray, setListArray] = useState([]);
  const [typeArray, setType] = useState([]);
  const [languageArray, setLanguage] = useState([]);
  const [NetworkArray, setNetwork] = useState([]);
  const [BoxtypeArray, setBoxtype] = useState([]);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isTypeSelectedNetwork, setIsTypeSelectedNetwork] = useState(false);
  const [isAddonsSelected, setIsAddonsSelected] = useState(false);
  const [isNetworkSelected, setIsNetworkSelected] = useState(false);
  const [value, setValue] = React.useState([]);
  const [languagevalue, setlanguageValue] = React.useState();
  const [networkvalue, setnetworkValue] = React.useState();
  const [selectedChannels, setSelectedChannels] = useState({});
  const [BouquetselectedChannels, setBouquetSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [NetworkAndGenre, setListNetworkGenre] = useState([]);
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState({});
  const [ChannelArray, setChannel] = useState([]);
  const [BoradcasterChannelArray, setBoradcasterChannel] = useState([]);

  const [NetworkChannelArray, setNetworkChannel] = useState([]);
  const [userData, setUserData] = useState(null);
  const [Channelvalue, setChannelValue] = React.useState([]);
  const [NetworkChannelvalue, setNetworkChannelValue] = React.useState([]);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [isPublished, setIsPublised] = useState(true);
  const [postedName, setPostedName] = useState();
  useEffect(() => {}, [networkvalue]);
  useEffect(() => {
    // getEditDetails(rowData);
    getData();
    getChannelList();
    getUserName();
    getLanguageList();
    getNetworkList();
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
    setUserDetails(JSON.parse(sessionStorage.getItem("userDetail")));
  }, [dataSource]);
  const [editData, setEditData] = useState(); // Add this line
  useEffect(() => {
    // This effect runs whenever editData changes Bouquet
    // getGenreList();
    console.log("Edit Data:", editData);
    const initialSelectedChannels = {};
    if (selectedOption === "Sundirect" && editData) {
      const transformedData = editData.genreDetails.flatMap(
        ({ GenreId, GenreName, channelsList }) =>
          channelsList.map(({ ChannelId, ChannelName }) => ({
            ChannelId,
            GenreId,
            GenreName,
            ChannelName,
          }))
      );
      setChannelValue(transformedData);
    }
    const initialBouquetSelectedChannels = {};
    if (selectedOption === "boradcaster" && editData) {
      // Access properties of editData safely
      // editData.genreDetails.forEach((genre) => {
      //   initialBouquetSelectedChannels[genre.GenreId] = genre.channelsList.map(
      //     (channel) => ({
      //       ...channel,
      //       label: channel.ChannelName,
      //       Id: channel.ChannelId,
      //     })
      //   );
      // });
      // console.log(initialBouquetSelectedChannels);
      // setBouquetSelectedChannels(initialBouquetSelectedChannels);
      const transformedData = editData.genreDetails.flatMap(
        ({ GenreId, GenreName, channelsList }) =>
          channelsList.map(({ ChannelId, ChannelName }) => ({
            ChannelId,
            GenreId,
            GenreName,
            ChannelName,
          }))
      );
      setNetworkChannelValue(transformedData);
    }
  }, [editData]); // Add editData as dependency
  const getData = async () => {
    try {
      const res = await ApiService.getOne("sdmbouquet", dataSource);
      if (res) {
        setBouquetStatus(
          res.BouquetStatus == "Published" ? "approval" : res.BouquetStatus
        );
        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater(res.IsPublishLater);
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setApprovedOn(res.ApprovedOn);
        setApprovedBy(res.ApprovedBy);
        setIsPublished(res.IsPublished);
        setFormData((prevData) => ({
          ...prevData,
          BouquetName: res.BouquetName,
          Description: res.Description,
          BouquetSmsCode: res.BouquetSmsCode,
          IsBcBouquet: res.IsBcBouquet,
          IsSdBouquet: res.IsSdBouquet,
          ShowInFTA:res.ShowInFTA,
          Poid: res.Poid,
          Price: res.Price,
          SystemName: res.SystemName,
        }));
        setSelectedOption(
          res.IsSdBouquet == true
            ? "Sundirect"
            : res.IsBcBouquet == true
            ? "boradcaster"
            : ""
        );
        setEditData(res);
        setlanguageValue(res.languageDetails[0]);
        setnetworkValue(res.networkDetails[0]);
        if(res.IsBcBouquet){
          getBoradcasterChannelList(
            res.networkDetails ? res.networkDetails[0].NetworkId : 0
          );
        }
       
      }

      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  const getUserName = async () => {
    try {
      const res = await ApiService.getOne("sdmusers", userDetails.Id);
      if (res) {
        console.log(res);
        setPostedName(res.FirstName);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleApprovePublish = (e) => {
    e.preventDefault();
      onSubmit();
  };

  const onSubmit = async () => {
    const transformedArray = Channelvalue.reduce((acc, curr) => {
      // Check if the genre exists in the accumulator array
      const existingGenre = acc.find((item) => item.GenreId === curr.GenreId);
      if (existingGenre) {
        // If the genre exists, add the channel to its NetworkGenreChannel array
        existingGenre.channelsList.push({
          ChannelId: curr.ChannelId,
        });
      } else {
        // If the genre doesn't exist, create a new entry for it in the accumulator array
        acc.push({
          GenreId: curr.GenreId,
          channelsList: [
            {
              ChannelId: curr.ChannelId,
            },
          ],
        });
      }
      return acc;
    }, []);
    const genreDetails = NetworkChannelvalue.reduce((acc, curr) => {
      const existingGenre = acc.find((item) => item.GenreId === curr.GenreId);
      if (existingGenre) {
        existingGenre.channelsList.push({ ChannelId: curr.ChannelId });
      } else {
        acc.push({
          GenreId: curr.GenreId,
          channelsList: [{ ChannelId: curr.ChannelId }],
        });
      }
      return acc;
    }, []);
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const BouquetData = {
      BouquetName: formData.BouquetName,
      SystemName: formData.SystemName,
      Price: formData.Price,
      Poid: formData.Poid,
      IsSdBouquet: formData.IsSdBouquet,
      ShowInFTA:formData.ShowInFTA,
      IsBcBouquet: formData.IsBcBouquet,
      BouquetSmsCode: formData.BouquetSmsCode,
      Description: formData.Description,
      BouquetStatus: BouquetStatus,
      LanguageId: languagevalue ? [languagevalue.LanguageId] :[],
      NetworkId: networkvalue ? [networkvalue.Id] : [],
      genreDetails: formData.IsBcBouquet ? genreDetails : transformedArray,
      IsPublishLater: isPublishLater,
      ScheduledPublishDt: formattedScheduledPublishDt,
      IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsPublished: IsPublished,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      ApprovedOn: ApprovedOn,
      ApprovedBy: ApprovedBy,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: isActive == "Active" ? true : false,
    };
    console.log(BouquetData);
    try {
      const res = await ApiService.put("sdmbouquet", BouquetData);
      if (res) {
        // if(isPublished){
          enqueueSnackbar("Updated Successfully!", { variant: "success" });
            onCancel();
        // }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved,
      IsPublishedLater: isPublishLater,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
      const res = await ApiService.put("sdmbouquet/approve", publishData);
      // if (res) {
        enqueueSnackbar("Published Successfully!", {
          variant: "success",
        });
      onCancel();
      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setBouquetStatus(val);
    if (val != "scheduled") {
      setScheduledPublishDt(null);
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true);
    } else {
      setIsApproved(false);
      setIsPublished(false);
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    onSubmit();
    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const formattedDate = formatDate(editData ? editData.CreatedOn : "");
  const ModifiedformattedDate = formatDate(
    editData ? editData.ModifiedOn : "-"
  );

  const handlenetwork = (newValue) => {
    setNetworkChannelValue([]);
    if (!newValue) {
      setnetworkChannelData([]);
    }
  };
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/sdchannellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getBoradcasterChannelList = async (params) => {
    try {
      const res = await ApiService.getOne(
        "sdmbouquet/genrechannellist",
        params
      );
      if (res) {
        setBoradcasterChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const [networkBasedChannels, setnetworkBasedChannels] = useState([]);
  const [networkChannelData, setnetworkChannelData] = useState([]);
  const getchannelListBasedOnNetwork = async (params) => {
    try {
      const res = await ApiService.getOne("sdmnetwork", params);
      if (res) {
        setnetworkBasedChannels(res.NetworkGenres);
        // console.log(networkBasedChannels)
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {
    // console.log(networkBasedChannels);
    let networkChannelDatas = addGenreIdToChannels(networkBasedChannels);
    setnetworkChannelData(networkChannelDatas);
    console.log(networkChannelData);
  }, [networkBasedChannels]);
  const addGenreIdToChannels = (networkBasedChannels) => {
    return networkBasedChannels.map((item) => {
      const channelsWithGenreId = item.NetworkGenreChannel.map((channel) => ({
        ...channel,
        GenreId: item.GenreId,
      }));
      return {
        ...item,
        NetworkGenreChannel: channelsWithGenreId,
      };
    });
  };
  const getLanguageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmlanguage/list", typeRequest);
      if (res) {
        setLanguage(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getNetworkList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setNetworkLoading(true);
      const res = await ApiService.post("sdmnetwork/list", typeRequest);
      if (res) {
        setNetwork(res);
        setNetworkLoading(false);
      }
    } catch (error) {
      setNetworkLoading(false);
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, [userData]);

  const radiohandlechange = (e) => {
    setSelectedOption(e.target.value);
    setFormData((prevData) => ({
      ...prevData,
      IsSdBouquet: e.target.value === "Sundirect" ? true : false,
      IsBcBouquet: e.target.value === "boradcaster" ? true : false,
    }));
    if (e.target.value === "Sundirect") {
      setnetworkValue();
      setnetworkChannelData([]);
      setNetworkChannelValue([]);
    }
    if (e.target.value === "boradcaster") {
      setChannelValue([]);
    }
    console.log(selectedOption);
    console.log(e.target);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "ShowInFTA") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;

    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }

    // Regular expression to match only numeric characters and a single dot
    const isValidInput = /^[0-9.]+$/.test(e.key);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
      e.preventDefault();
    }

    // Prevent input of more than one dot
    const value = e.target.value;
    if ((keynum === 46 || keynum === 190) && value.includes(".")) {
      e.preventDefault();
    }
  };
  const handleChanges = (genreId, selectedChannels, genreName) => {
    setSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]:
        selectedChannels.length > 0
          ? selectedChannels.map((channel) => ({
              ...channel,
              value: channel.ChannelId, // Set the ChannelId as value
              label: channel.ChannelName,
            }))
          : [], // Set an empty array if selectedChannels is empty
    }));
    setSelectedGenre((prevState) => ({
      ...prevState,
      [genreId]: genreName,
    }));
    console.log(selectedChannels);
  };
  const BouquethandleChanges = (
    genreId,
    BouquetselectedChannels,
    genreName
  ) => {
    setBouquetSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]:
        BouquetselectedChannels.length > 0
          ? BouquetselectedChannels.map((channel) => ({
              ...channel,
              value: channel.ChannelId, // Set the ChannelId as value
              label: channel.ChannelName,
            }))
          : [], // Set an empty array if selectedChannels is empty
    }));
    setSelectedGenre((prevState) => ({
      ...prevState,
      [genreId]: genreName,
    }));
    console.log(BouquetselectedChannels);
  };
  return (
    <div>
      {loading && <Loader />}
      {Networkloading && <Loader />}

      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Bouquet</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Bouquet Name"
                  name="BouquetName"
                  value={formData.BouquetName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="System Name"
                  name="SystemName"
                  value={formData.SystemName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Poid"
                  name="Poid"
                  value={formData.Poid}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  maxLength={25}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports numbers upto 25 chars.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Bouquet Sms Code"
                  name="BouquetSmsCode"
                  value={formData.BouquetSmsCode}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Price"
                  name="Price"
                  value={formData.Price}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              {languageArray.SdMLanguage &&
                languageArray.SdMLanguage.length > 0 && (
                  <div className="exmple-container">
                    <div className="w254">
                      <Autocomplete
                        id="fixed-tags-demo3"
                        value={languagevalue}
                        onChange={(event, newValue) => {
                          setlanguageValue(newValue);
                          console.log(newValue);
                        }}
                        options={languageArray.SdMLanguage.filter(
                          (option) =>
                            !value.some((selected) => selected.Id === option.Id)
                        )}
                        getOptionLabel={(option) => option.LanguageName}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.LanguageName}
                              {...getTagProps({ index })}
                              className="chipsStyle"
                              // disabled={ListArray.indexOf(option) !== -1}
                            />
                          ))
                        }
                        // style={{ width: 858, marginBottom: 24 }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Language"
                            placeholder="Language"
                            required
                            variant="standard"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
            </div>
            <div className="example-container m-b">
              <MaterialUITextField
                className="commonInput multiSelectWidth"
                label="Bouquet Description"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                multiline
                maxLength={100}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers, <Space> & - and + upto 100 chars."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="displayFlex m-b">
              <div className="exmple-container m-b">
                <div className="m-24">
                  <p className="post">Bouquet Type</p>
                  <RadioGroup
                    name="bouquet"
                    value={selectedOption}
                    onChange={radiohandlechange}
                    row
                  >
                    <FormControlLabel
                      value="Sundirect"
                      control={
                        <Radio
                          sx={{
                            color: "#EE3823",
                            "&.Mui-checked": { color: "#EE3823" },
                          }}
                        />
                      }
                      label="Sundirect"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="boradcaster"
                      control={
                        <Radio
                          sx={{
                            color: "#EE3823",
                            "&.Mui-checked": { color: "#EE3823" },
                          }}
                        />
                      }
                      label="Broadcaster"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="example-container">
                {/* <div className="commonInput mt22">
                  <Checkbox
                    checked={formData.ShowInFTA}
                    name="ShowInFTA"
                    onChange={handleChange}
                    sx={{
                      color: "#EE3823",
                      "&.Mui-checked": {
                        color: "#EE3823",
                      },
                    }}
                  />
                  Show In FTA
                </div> */}
              </div>
            </div>

            {formData.IsBcBouquet && (
              <div>
                {NetworkArray.SdMNetwork &&
                  NetworkArray.SdMNetwork.length > 0 && (
                    <div className="exmple-container m-b">
                      <div className="commonInput m-24">
                        <Autocomplete
                          id="fixed-tags-demo3"
                          value={networkvalue}
                          onChange={(event, newValue) => {
                            setnetworkValue(newValue);
                            setIsTypeSelectedNetwork(
                              newValue ? newValue.length > 0 : false
                            );
                            getBoradcasterChannelList(
                              newValue ? newValue.Id : 0
                            );
                            console.log(newValue);
                            handlenetwork(newValue);
                          }}
                          options={NetworkArray.SdMNetwork.filter(
                            (option) =>
                              !value.some(
                                (selected) => selected.Id === option.Id
                              )
                          )}
                          getOptionLabel={(option) => option.NetworkName}
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                label={option.NetworkName}
                                {...getTagProps({ index })}
                                className="chipsStyle"
                                // disabled={ListArray.indexOf(option) !== -1}
                              />
                            ))
                          }
                          style={{ width: 858, marginBottom: 24 }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label="Network"
                              placeholder="Network"
                              required={!isTypeSelectedNetwork}
                              variant="standard"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                {/* {networkChannelData && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={NetworkChannelvalue}
                    onChange={(event, newValue) => {
                      setNetworkChannelValue(newValue);
                    }}
                    options={networkChannelData.flatMap(
                      (item) => item.NetworkGenreChannel
                    )}
                    getOptionLabel={(option) => option.ChannelName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.ChannelName}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Network Based Channels"
                        variant="standard"
                        placeholder="Please select network"
                      />
                    )}
                  />
                </div>
              </div>
            )} */}

                {networkChannelData && (
                  <div className="exmple-container m-b">
                    <div className="commonInput">
                      <Autocomplete
                        multiple
                        id="fixed-tags-demo3"
                        value={NetworkChannelvalue}
                        onChange={(event, newValue) => {
                          setNetworkChannelValue(newValue);
                          console.log(NetworkChannelvalue);
                        }}
                        options={ChannelArray}
                        getOptionLabel={(option) => option.ChannelName}
                        getOptionDisabled={(option) =>
                          NetworkChannelvalue.some(
                            (selected) =>
                              selected.ChannelId === option.ChannelId
                          )
                        }
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              key={index}
                              label={option.ChannelName}
                              {...getTagProps({ index })}
                              className="chipsStyle"
                            />
                          ))
                        }
                        style={{ width: 858, marginBottom: 24 }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Network Based Channels"
                            placeholder="Please select network"
                            variant="standard"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {formData.IsSdBouquet && (
              <div>
                {!isNetworkSelected &&
                  ChannelArray &&
                  ChannelArray.length > 0 && (
                    <div className="exmple-container m-b">
                      <div className="commonInput">
                        <Autocomplete
                          multiple
                          id="fixed-tags-demo3"
                          value={Channelvalue}
                          onChange={(event, newValue) => {
                            setChannelValue(newValue);
                            console.log(Channelvalue);
                          }}
                          options={ChannelArray}
                          getOptionLabel={(option) => option.ChannelName}
                          getOptionDisabled={(option) =>
                            Channelvalue.some(
                              (selected) =>
                                selected.ChannelId === option.ChannelId
                            )
                          }
                          renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                              <Chip
                                key={index}
                                label={option.ChannelName}
                                {...getTagProps({ index })}
                                className="chipsStyle"
                              />
                            ))
                          }
                          style={{ width: 858, marginBottom: 24 }}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              label="Channels"
                              placeholder="Channels"
                              variant="standard"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

            <div className="displayFlex m-b">
              <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {userDetails &&
                (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                  <div className="displayFlex">
                    <div className="example-container">
                      <FormControl>
                        <InputLabel>Approval Status</InputLabel>
                        <Select
                          className="commonInput"
                          value={BouquetStatus}
                          onChange={(e) =>
                            onApprovalStatusChange(e.target.value)
                          }
                          required
                        >
                          {ApproveStatus.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.viewValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    <p className="imgInfo">[Select Approval to publish]</p>
                    </div>
                    <div className="example-container">
                      <MaterialUITextField
                        className="commonInput"
                        label="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </div>
                )}
            </div>
            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex">
                  {BouquetStatus === "scheduled" && (
                    <div className="example-container">
                      <MaterialUITextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={
                          scheduledPublishDt
                            ? scheduledPublishDt.split("T")[0]
                            : scheduledPublishDt
                        }
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ""} By{" "}
                  {editData ? editData.CreatedByName : ""}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ""} By{" "}
                  {editData ? editData.ModifiedByName : "-"}
                </p>
              </div>
            </div>
          </div>
          <div className="submitButton">
          {BouquetStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {BouquetStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}
            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BoquetPageEdit;
