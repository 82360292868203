import React, { useState, useEffect } from 'react';
import leftArrow from './../../../../images/left-arrow.svg';
import { Button, MenuItem, FormControl, InputLabel, Select } from '@material-ui/core';
import TextField from '@mui/material/TextField';

import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from './../../../../Services/ApiService'
import CryptoJS from 'crypto-js';

import {
  withStyles
} from '@material-ui/core/styles';
import { Password } from '@mui/icons-material';
const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#EE3823',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#EE3823',
    },
    '& .MuiInput-underline:hover': {
      borderBottomColor: '#EE3823',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField);

const CssSelect = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(Select)
const UserAdd = ({ onCancel }) => {
  const [formData, setFormData] = useState({
    UserName: '',
    Password: '',
    FirstName: '',
    LastName: '',
    Email: '',
    RoleId: '',
    IsActive: 'Active',
  });

  const [roleOptions, setroleOptions] = useState(null);
  const fetchData = async () => {
    const filterData = {
      "Index": 0,
      "Limit": 100000,
      "OrderByTable": "",
      "OrderByField": "",
      "Sort": "",
      "Aggregator": "",
      "Conditions": [

      ]
    }
    const response = ApiService.post('sdmuserrole/list', filterData)
      .then(response => {
        setroleOptions(response.SdMUserrole)

      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });

  }
  useEffect(() => {

    fetchData();

  }, []);
  const [loading, setLoading] = useState(true);

  const statusOptions = [
    { value: 'Active', viewValue: 'Active' },
    { value: 'Inactive', viewValue: 'Inactive' },
  ];
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = sessionStorage.getItem('userDetail');

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    console.log(formData.Password)

  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission

    const updatedFormData = {
      ...formData,
      Password: encryptData(formData.Password),
      CreatedOn: getCurrentDateTime(),
      CreatedBy: userData.Id,
      IsActive: formData.IsActive == "Active" ? true : false,
      SdMUserrole: {}
    };
    setFormData(updatedFormData);
    try {
      const res = await ApiService.post("sdmusers", updatedFormData);
      if (res) {
        handleCancel()
      }
    } catch (error) {
      console.error('Error updating toggle state:', error);
    }
    console.log('Form submitted:', updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };

  function encryptData(plainText) {
    const secretKey = "SunDirectAdminLoginPSWDSecretKey";
    const salt = "SUNDIRECTLOGIN01";
    // take first 32 bytes as key (like in C# code)
    var key = CryptoJS.enc.Utf8.parse(secretKey);
    // skip first 32 bytes and take next 16 bytes as IV
    var iv = CryptoJS.enc.Utf8.parse(salt);
    // use the same encoding as in C# code, to convert string into bytes
    var data = CryptoJS.enc.Utf8.parse(plainText);
    var encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    console.log(encrypted.toString());
    return encrypted.toString();
  }


  return (
    <div className='mainContainer'>
      <div class="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className='cursor' />
        <p>Create User Account</p>
      </div>
      <form onSubmit={handleSubmit} className='m-t-24'>
        <div className="Content">
          <div className="displayFlex m-b">
            <div className="example-container">
              <CssTextField
                label="Username"
                id="custom-css-standard-input"
                name="UserName"
                value={formData.UserName}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 50 }}
                variant='standard'
                className='commonInput'
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className='Icon-style' />
                    </Tooltip>
                  ),
                }}

              />
            </div>
            <div className="example-container">
              <CssTextField
                type="password"
                label="Password"
                name="Password"
                variant='standard'
                className='commonInput'
                value={formData.Password}
                onChange={handleChange}
                required
              />
            </div>
            <div className="example-container">
              <CssTextField
                label="Firstname"
                name="FirstName"
                variant='standard'
                value={formData.FirstName}
                onChange={handleChange}
                required
                className='commonInput'
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className='Icon-style' />
                    </Tooltip>
                  ),
                }}
              />
            </div>
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <CssTextField
                label="Lastname"
                name="LastName"
                variant='standard'
                value={formData.LastName}
                onChange={handleChange}
                required
                className='commonInput'
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className='Icon-style' />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <CssTextField
                label="Email"
                name="Email"
                variant='standard'
                value={formData.Email}
                onChange={handleChange}
                required
                className='commonInput'
                error={formData.Email && !/^\S+@\S+\.\S+$/.test(formData.Email)}
                helperText={formData.Email && !/^\S+@\S+\.\S+$/.test(formData.Email) && 'Please enter a valid email address.'}
              />
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Role</InputLabel>
                <Select
                  name="RoleId"
                  value={formData.RoleId}
                  onChange={handleChange}
                  required
                  className='commonInput'
                >
                  {roleOptions !== null ? (
                    roleOptions.map((role) => (
                      <MenuItem key={role.Id} value={role.Id}>{role.RoleName}</MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="example-container m-b">
            <FormControl >
              <InputLabel>Status</InputLabel>
              <Select
                name="IsActive"
                value={formData.IsActive}
                onChange={handleChange}
                required
                className='commonInput'
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">Save</Button>
          <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
        </div>
      </form>
    </div>
  );
};

export default UserAdd;