import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const ContentPageAdd = ({ onCancel, dataSource }) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ image: "" }], // Add image button to toolbar
    [{ html: "Toggle HTML" }], // Add custom HTML source button to toolbar
    ["clean"],
];
  const [categoryData, setcategoryData] = useState(null);
  const [imageError, setimageError] = useState(null);
  const [imageFile, setimageFile] = useState(null); // State for icon file
  const [templateData, settemplateData] = useState(null);
  const fetchData = async () => {
    const response = await ApiService.getAll("common/template");
    settemplateData(response);
  };
  const fetchCategoryData = async () => {
    const filterData = {
      Index: 0,
      Limit: 100000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    const response = await ApiService.post("sdmcategories/list", filterData);
    setcategoryData(response.SdMCategories);
  };

  useEffect(() => {
    fetchCategoryData();
    fetchData();
  }, []);
  const [formData, setFormData] = useState({
    TemplateId: dataSource.TemplateId,
    PageKey: dataSource.PageKey,
    ContentTitle: dataSource.ContentTitle,
    Content: dataSource.Content,
    ImageUrl: dataSource.ImageUrl,
    Notes: dataSource.Notes,
    Widges1: dataSource.Widges1,
    Type1: dataSource.Type1,
    Widges2: dataSource.Widges2,
    Type2: dataSource.Type2,
    MetaPageTitle: dataSource.MetaPageTitle,
    MetaKeywords: dataSource.MetaKeywords,
    MetaDescription: dataSource.MetaDescription,
    Status: dataSource.Status,
    IsActive: dataSource.IsActive == true ? "Active" : "Inactive",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const reviewOptions = [
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "published", viewValue: "Published" },
  ];
  const TypesOptions = [
    { value: "logoGrid", viewValue: "Logo Grid" },
    { value: "slider", viewValue: "Slider" },
    { value: "grid", viewValue: "Grid" },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const updatedFormData = {
      ...formData,
      Id: dataSource.Id,
      CreatedOn: dataSource.CreatedOn,
      CreatedBy: dataSource.CreatedBy,
      ModifiedBy: userData.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    if (imageFile) {
      updatedFormData.ImageUrl = imageFile.name;
    }
    setFormData(updatedFormData);
    try {
      const res = await ApiService.put("sdmcontentpages", updatedFormData);
      if (res) {
        const uploadedImages = {
          'Id': dataSource.Id,
          'FilePath':imageFile,
        }
        try{
        const imgApi = await ApiService.put('sdmcontentpages/uploadimage',uploadedImages) 
        // if(imgApi){
        handleCancel();
        // }
        } catch (error){
      console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleChange1 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Content: content,
    }));
  };
  const handleChange2 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Notes: content,
    }));
  };
  const handleimageFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the icon file
      if (selectedFile.size > 2 * 1024 * 1024) {
        setimageError("Icon size should not exceed 2MB.");
        setimageFile(null);
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setimageError(
          "Only JPG, JPEG, PNG, BMP, and GIF formats are supported."
        );
        setimageFile(null);
      } else {
        setimageFile(selectedFile);
        setimageError(null);
      }
    }
  };
  const clearimageFile = () => {
    setimageFile(null);
    setimageError(null);
  };
  const clearimageFileEdit = () => {
    setFormData((prevData) => ({
      ...prevData,
      ImageUrl: null,
    }));
    setimageError(null);
  };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Content Page</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
        <div className="Content">
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Template</InputLabel>
                <Select
                  name="TemplateId"
                  value={formData.TemplateId}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {templateData !== null ? (
                    templateData.map((type) => (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.Name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <TextField
                label="Page Key"
                name="PageKey"
                value={formData.PageKey}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <TextField
                label="Content Title"
                name="ContentTitle"
                value={formData.ContentTitle}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
          </div>
          <div className="example-container m-b">
            <div className="checkbox-lable m-24">Content</div>
            <ReactQuill
              theme="snow"
              className="quill-editor multiSelectWidth"
              value={formData.Content}
              onChange={handleChange1}
              modules={{
                toolbar: toolbarOptions,
              }}
              placeholder={"Write something"}
            />
          </div>
          <div className="example-container">
            <div className="checkbox-lable m-24">
              Content Image *
              <span className="cursor imgInfoIcon">
                <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                  <InfoIcon className="Icon-style svg-info " />
                </Tooltip>
              </span>
            </div>
            <div className="disflex">
              <div>
                <label htmlFor="imageFileInput" className="upload-file-label">
                  Choose Files
                </label>
                <input
                  className="upload-file"
                  type="file"
                  id="imageFileInput"
                  onChange={handleimageFileChange}
                />
              </div>
              <span>
                {imageFile ? (
                  <a href="#" className="uploaded-file-link">
                    {imageFile.name}
                  </a>
                ) : (
                  <a href="#" className="uploaded-file-link">
                    {formData.ImageUrl}
                  </a>
                )}
                {imageFile ? (
                  <CloseIcon
                    className="closeUpload cursor"
                    onClick={clearimageFile}
                  />
                ) : formData.ImageUrl ? (
                  <CloseIcon
                    className="closeUpload cursor"
                    onClick={clearimageFileEdit}
                  />
                ) : null}
              </span>
            </div>
            {imageError && <div className="imgError">{imageError}</div>}
            <p className="imgInfo">
              [Recommended for this image pixel width(200) x height(150)]
            </p>
          </div>
          <div className="example-container m-b">
            <div className="checkbox-lable m-24">Notes</div>
            <ReactQuill
              theme="snow"
              className="quill-editor multiSelectWidth"
              value={formData.Notes}
              onChange={handleChange2}
              modules={{
                toolbar: toolbarOptions,
              }}
              placeholder={"Write something"}
            />
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Widget One</InputLabel>
                <Select
                  name="Widges1"
                  value={formData.Widges1}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {categoryData !== null ? (
                    categoryData.map((type) => (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.CategoryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Select Type</InputLabel>
                <Select
                  name="Type1"
                  value={formData.Type1}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {TypesOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Widget Two</InputLabel>
                <Select
                  name="Widges2"
                  value={formData.Widges2}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {categoryData !== null ? (
                    categoryData.map((type) => (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.CategoryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Select Type</InputLabel>
                <Select
                  name="Type2"
                  value={formData.Type2}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {TypesOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta / PageTitle"
              name="MetaPageTitle"
              value={formData.MetaPageTitle}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta Keywords"
              name="MetaKeywords"
              value={formData.MetaKeywords}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container m-b">
            <TextField
              label="Meta Description"
              name="MetaDescription"
              value={formData.MetaDescription}
              onChange={handleChange}
              required
              maxLength={50}
              className="commonInput multiSelectWidth"
              InputProps={{
                endAdornment: (
                  <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className="Icon-style" />
                  </Tooltip>
                ),
              }}
            />
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Review Status</InputLabel>
                <Select
                  name="Status"
                  value={formData.Status}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {reviewOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContentPageAdd;
