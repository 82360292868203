import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import "./../../../../src/styles/common.css"; // Import common styles
import { DialogBox } from "../../Dialog Box";
import ApiService from "../../../Services/ApiService";
import { SnackbarProvider, useSnackbar } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Loader } from '../../Loader';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PackagePriority = ({
  onCancel,
  name,
  dataSource,
  apiUrl,
  priorityApiUrl,
  tableName,
}) => {
  const [loading, setLoading] = useState(true);
  const [genreList, setGenreList] = useState([]);
  const [newPackageList, setnewPackageList] = useState([]);
  const [exisPackageList, setexisPackageList] = useState([]);
  const [exisPackageOfferList, setexisPackageOfferList] = useState([]);
  const [PackageList, setPackageList] = useState([]);
  const [PackageGenreList, setPackageGenreList] = useState([]);
  const [PackageGenreChannelList, setPackageGenreChannelList] = useState([]);
  const [PackageChannelList, setPackageChannelList] = useState([]);
  const [newdroppedItem, setnewDroppedItem] = useState(null);
  const [newoldPriority, setnewOldPriority] = useState(null);
  const [newdraggedItem, setnewDraggedItem] = useState(null);
  const [newdialogOpen, setnewDialogOpen] = useState(false);
  const [offerdroppedItem, setofferDroppedItem] = useState(null);
  const [offeroldPriority, setofferOldPriority] = useState(null);
  const [offerdraggedItem, setofferDraggedItem] = useState(null);
  const [offerdialogOpen, setofferDialogOpen] = useState(false);
  const [exisdroppedItem, setexisDroppedItem] = useState(null);
  const [exisoldPriority, setexisOldPriority] = useState(null);
  const [exisdraggedItem, setexisDraggedItem] = useState(null);
  const [exisdialogOpen, setexisDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  const [packageGenredialogData, setpackageGenreDialogData] = useState();
  const [packageGenreDialogOpen, setpackageGenreDialogOpen] = useState(false);
  const [packageGenreChannelDialogOpen, setpackageGenreChannelDialogOpen] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedchannalgenre, setselectedchannalgenre] = useState(null);
  const [packageGenredroppedItem, setpackageGenreDroppedItem] = useState(null);
  const [packageGenreOldPriority, setpackageGenreOldPriority] = useState(null);
  const [packageGenredraggedItem, setpackageGenreDraggedItem] = useState(null);
  const [packageGenreChanneldroppedItem, setpackageGenreChannelDroppedItem] = useState(null);
  const [packageGenreChannelOldPriority, setpackageGenreChannelOldPriority] = useState(null);
  const [packageGenreChanneldraggedItem, setpackageGenreChannelDraggedItem] = useState(null);
  const [newPackageId,setnewPackageId]=useState();
  const [offerPackageId,setofferPackageId]=useState();
  const [exisPackageId,setexisPackageId]=useState();
  const [PackageGenreId,setPackageGenreId]=useState();
const [currentPackageId,setcurrentPackageId]=useState();
const [currentGenreId,setcurrentGenreId]=useState();

  useEffect(() => {
    fetchData();
    existingfetchData();
    existingOfferfetchData();
    PackagefetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiService.getAll(
        "common/package/newuser/priority"
      );
      if (response) {
        setnewPackageList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const existingfetchData = async () => {
    try {
      const response = await ApiService.getAll(
        "common/package/existinguser/priority"
      );
      if (response) {
        setexisPackageList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const existingOfferfetchData = async () => {
    try {
      const response = await ApiService.getAll(
        "common/package/existinguserofferpack/priority"
      );
      if (response) {
        setexisPackageOfferList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const PackageGenreData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/package/genere/priority",
        id
      );
      if (response) {
        setPackageGenreList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const PackageChannelData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/package/genere/priority",
        id
      );
      if (response) {
        setPackageChannelList(response);
        console.log(response)
        setselectedchannalgenre(response[0])
        PackageGenreChannelData(response[0].GenreId);
        setcurrentGenreId(response[0].GenreId)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const PackageGenreChannelData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/package/channel/priority",
        id
      );
      if (response) {
        setPackageGenreChannelList(response);
        // console.log(response)
        // setselectedchannalgenre(response[0])
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#EE3823",
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const newhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    setnewOldPriority(oldNo);
    setnewDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const newhandleDragOver = (event) => {
    event.preventDefault();
  };

  const newhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setnewDroppedItem(droppedItem);
    setnewPackageId(droppedItem)
    newhandleDialogOpen();
  };

  const newhandleDialogOpen = () => {
    setnewDialogOpen(true);
  };

  const newhandleDialogClose = () => {
    setnewDialogOpen(false);
  };

  const newhandleDialogConfirm = async () => {
    const oldIndex = newPackageList.findIndex((item) => item === newdraggedItem);
    let newIndex = newPackageList.findIndex((item) => item === newdroppedItem);
    const newData = [...newPackageList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, newdraggedItem);
    console.log(newdraggedItem,newdroppedItem.PriorityNo);
    newIndex = newdroppedItem.PriorityNo
    setnewPackageList(newData);
    newhandleDialogClose();

    const req = {
      OldPriority: newoldPriority,
      NewPriority: newIndex,
      PrimaryId:newPackageId.PackageId
    };
    try {
      const res = await ApiService.put("sdmpackage/newuserpackage/priority", req);
      if (res) {
        fetchData()
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };


  const offerhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    setofferOldPriority(oldNo);
    setofferDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const offerhandleDragOver = (event) => {
    event.preventDefault();
  };

  const offerhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setofferDroppedItem(droppedItem);
    setofferPackageId(droppedItem)
    offerhandleDialogOpen();
  };

  const offerhandleDialogOpen = () => {
    setofferDialogOpen(true);
  };

  const offerhandleDialogClose = () => {
    setofferDialogOpen(false);
  };

  const offerhandleDialogConfirm = async () => {
    const oldIndex = offerPackageId.findIndex((item) => item === newdraggedItem);
    const newIndex = offerPackageId.findIndex((item) => item === newdroppedItem);
    const newData = [...exisPackageOfferList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, offerdraggedItem);
    setexisPackageOfferList(newData);
    newhandleDialogClose();

    const req = {
      OldPriority: offeroldPriority,
      NewPriority: newIndex + 1,
      PrimaryId:offerPackageId.PackageId
    };
    try {
      const res = await ApiService.put("sdmpackage/existinguserofferpackage/priority", req);
      if (res) {
        existingOfferfetchData()
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const exishandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    setexisOldPriority(oldNo);
    setexisDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const exishandleDragOver = (event) => {
    event.preventDefault();
  };

  const exishandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setexisDroppedItem(droppedItem);
    setexisPackageId(droppedItem);
    console.log(droppedItem)
    exishandleDialogOpen();
  };

  const exishandleDialogOpen = () => {
    setexisDialogOpen(true);
  };

  const exishandleDialogClose = () => {
    setexisDialogOpen(false);
  };

  const exishandleDialogConfirm = async () => {
    const oldIndex = exisPackageList.findIndex((item) => item === exisdraggedItem);
    const newIndex = exisPackageList.findIndex((item) => item === exisdroppedItem);
    const newData = [...exisPackageList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, exisdraggedItem);
    setexisPackageList(newData);
    exishandleDialogClose();

    const req = {
      OldPriority: exisoldPriority,
      NewPriority: newIndex + 1,
      PrimaryId:exisPackageId.PackageId
    };
    try {
      const res = await ApiService.put("sdmpackage/existinguserpackage/priority", req);
      if (res) {
        existingfetchData()
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };


  const handlePackageGenre = (params) => {
    
    if (params) {
        setcurrentPackageId(params.Id)
        PackageGenreData(params.Id);
    }
  };
  const handleChannelGenre = (params) => {
    
    if (params) {
        setcurrentPackageId(params.Id)
        PackageChannelData(params.Id);
    }
  };
  const handleGenreBasedChannel = (params)=>{
    if (params) {
        setcurrentGenreId(params.GenreId)
        PackageGenreChannelData(params.GenreId);
    }
  }

  const packageGenrehandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    console.log(item);
    setpackageGenreOldPriority(oldNo);
    setpackageGenreDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const packageGenrehandleDragOver = (event) => {
    event.preventDefault();
  };

  const packageGenrehandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setpackageGenreDroppedItem(droppedItem);
    setPackageGenreId(droppedItem);
    console.log(droppedItem)
    packageGenrehandleDialogOpen();
  };

  const packageGenrehandleDialogOpen = () => {
    setpackageGenreDialogOpen(true);
  };

  const packageGenrehandleDialogClose = () => {
    setpackageGenreDialogOpen(false);
  };
  let packageGenrehandleDialogConfirm = async () => {
    const oldIndex = PackageGenreList.findIndex(
      (item) => item === packageGenredraggedItem
    );
    const newIndex = PackageGenreList.findIndex(
      (item) => item === packageGenredroppedItem
    );
    const newData = [...PackageGenreList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, packageGenredraggedItem);
    setPackageGenreList(newData);
    packageGenrehandleDialogClose();

    const req = {
      OldPriority: packageGenreOldPriority,
      NewPriority: newIndex + 1,
      PrimaryId: currentPackageId,
    };
    try {
      const res = await ApiService.put("sdmpackage/genre/priority", req);
      if (res) {
        PackageGenreData(currentPackageId)
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const packageGenreChannelhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    console.log(item);
    setpackageGenreChannelOldPriority(oldNo);
    setpackageGenreChannelDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const packageGenreChannelhandleDragOver = (event) => {
    event.preventDefault();
  };

  const packageGenreChannelhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setpackageGenreChannelDroppedItem(droppedItem);
    setPackageGenreId(droppedItem);
    console.log(droppedItem)
    packageGenreChannelhandleDialogOpen();
  };

  const packageGenreChannelhandleDialogOpen = () => {
    setpackageGenreChannelDialogOpen(true);
  };

  const packageGenreChannelhandleDialogClose = () => {
    setpackageGenreChannelDialogOpen(false);
  };
  let packageGenreChannelhandleDialogConfirm = async () => {
    const oldIndex = PackageGenreChannelList.findIndex(
      (item) => item === packageGenreChanneldraggedItem
    );
    const newIndex = PackageGenreChannelList.findIndex(
      (item) => item === packageGenreChanneldroppedItem
    );
    const newData = [...PackageGenreChannelList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, packageGenreChanneldraggedItem);
    setPackageGenreChannelList(newData);
    packageGenreChannelhandleDialogClose();

    const req = {
      OldPriority: packageGenreChannelOldPriority,
      NewPriority: newIndex + 1,
      PrimaryId: currentGenreId,
    };
    try {
      const res = await ApiService.put("sdmpackage/channel/priority", req);
      if (res) {
        PackageGenreChannelData(currentGenreId)
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const PackagefetchData = async () => {
    let filterData = {
      Index: 0,
      Limit: 1000000,
      OrderByTable: "SdMPackage",
      OrderByField: "Priority",
      Sort: "",
      Aggregator: "OR",
      Conditions: [],
    };

    try {
        setLoading(true)
      const response = await ApiService.getAll("sdmpackage/GetPackageDropDownList", filterData);
      if (response && response.dropdown) {
        setPackageList(response.dropdown);
        setSelectedGenre(response.dropdown[0]);
        setSelectedChannel(response.dropdown[0])
        setcurrentPackageId(response.dropdown[0].Id)
        setLoading(false)
        const id = response.dropdown[0].Id;
        if (id) {
            PackageGenreData(id);
            PackageChannelData(id)
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div>
        {loading && <Loader />}
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={newdialogOpen}
        onClose={newhandleDialogClose}
        onConfirm={newhandleDialogConfirm}
      />
       <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={offerdialogOpen}
        onClose={offerhandleDialogClose}
        onConfirm={offerhandleDialogConfirm}
      />
       <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={exisdialogOpen}
        onClose={exishandleDialogClose}
        onConfirm={exishandleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={packageGenreDialogOpen}
        onClose={packageGenrehandleDialogClose}
        onConfirm={packageGenrehandleDialogConfirm}
      />
       <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={packageGenreChannelDialogOpen}
        onClose={packageGenreChannelhandleDialogClose}
        onConfirm={packageGenreChannelhandleDialogConfirm}
      />
      <div className="mainContainer">
        <div className="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>Package Priority List</p>
        </div>
        <Box sx={{ width: "100%" }} className="Ptabs">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              indicatorColor="secondary"
              value={value}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#EE3823",
                  color: "#EE3823",
                  height: 3,
                  marginRight: 10,
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Priority for New User" {...a11yProps(0)} />
              <StyledTab label="Priority for Existing User" {...a11yProps(1)} />
              <StyledTab label="Priority for Existing User Offer Pack" {...a11yProps(2)} />
              <StyledTab label="Package Genre Priority" {...a11yProps(3)} />
              <StyledTab
                label="Package Genre Channel Priority"
                {...a11yProps(4)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Package</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={newhandleDragOver} className="w-100">
                    {newPackageList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => newhandleDragStart(e, item)}
                        onDrop={(e) => newhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Package</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={exishandleDragOver} className="w-100">
                    {exisPackageList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => exishandleDragStart(e, item)}
                        onDrop={(e) => exishandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Package</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={offerhandleDragOver} className="w-100">
                    {exisPackageOfferList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => offerhandleDragStart(e, item)}
                        onDrop={(e) => offerhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="w-254">
              <FormControl>
                <InputLabel>Package List</InputLabel>
                <Select
                  value={selectedGenre}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setSelectedGenre(newValue);
                    handlePackageGenre(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {PackageList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.packName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Genre</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={packageGenrehandleDragOver} className="w-100">
                    {PackageGenreList && PackageGenreList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => packageGenrehandleDragStart(e, item)}
                        onDrop={(e) => packageGenrehandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div className="displayFlex">
            <div className="w-254">
              <FormControl>
                <InputLabel>Package List</InputLabel>
                <Select
                  value={selectedChannel}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setSelectedChannel(newValue);
                    handleChannelGenre(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {PackageList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.packName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-254">
              <FormControl>
                <InputLabel>Genre List</InputLabel>
                <Select
                  value={selectedchannalgenre}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setselectedchannalgenre(newValue);
                    handleGenreBasedChannel(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {PackageChannelList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.PriorityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            </div>
          
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Genre</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={packageGenreChannelhandleDragOver} className="w-100">
                    {PackageGenreChannelList && PackageGenreChannelList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => packageGenreChannelhandleDragStart(e, item)}
                        onDrop={(e) => packageGenreChannelhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default PackagePriority;
