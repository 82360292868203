import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ReactQuill from 'react-quill';
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";

const CategoriesEdit = ({ onCancel, dataSource }) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'direction': 'rtl' }],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    [{ 'image': '' }], // Add image button to toolbar
    ['clean']
  ];
  const [formData, setFormData] = useState({
    CategoryName:dataSource.CategoryName ,
    Description:dataSource.Description,
    IconUrl:dataSource.IconUrl,
    CategorySlug:dataSource.CategorySlug,
    IsActive: dataSource.IsActive == true ? "Active" : "Inactive",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const updatedFormData = {
      ...formData,
      Id: dataSource.Id,
      CreatedOn: dataSource.CreatedOn,
      CreatedBy: dataSource.CreatedBy,
      ModifiedBy: userData.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    setFormData(updatedFormData);
    try {
      const res = await ApiService.put("sdmcategories", updatedFormData);
      if (res) {
        const uploadedImages = {
          'Id': dataSource.Id,
          'FilePath':file
        }
        try{
        const imgApi = await ApiService.put('sdmcategories/uploadimage',uploadedImages) 
        // if(imgApi){
        handleCancel();
        // }
        } catch (error){
      console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleChange1=(content)=>{
    // const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      "Description": content,
    }));
  }
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError("Image size should not exceed 2MB.");
        setFile(null); // Clear the file state
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setError("Only JPG, JPEG, PNG, BMP, and GIF formats are supported.");
        setFile(null); // Clear the file state
      } else {
        setFile(selectedFile);
        setFormData((prevData) => ({
          ...prevData,
          IconUrl: selectedFile.name,
        }));
          // console.log(formData.LogoUrl)
          setError(null); // Clear any existing error
        // onFileSelected(selectedFile);
      }
    }
  };
  useEffect(() => {}, [formData.IconUrl]);
  const clearFile = () => {
    setFile(null);
    setFormData((prevData) => ({
      ...prevData,
      IconUrl: null,
    }));
    setError(null);
  };
  const clearFileEdit = () => {

   setFormData((prevData) => ({
      ...prevData,
      IconUrl: null,
    }));
    setError(null);
  };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Category</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Category Name"
              name="CategoryName"
              value={formData.CategoryName}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container p-b-29">
                <div className="checkbox-lable m-24">
                Category Icon
                  <span className="cursor imgInfoIcon">
                    <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                      <InfoIcon className="Icon-style svg-info " />
                    </Tooltip>
                  </span>
                </div>
                <div className="disflex">
                  <div>
                    <label htmlFor="fileInput" className="upload-file-label">
                      Choose Files
                    </label>
                    <input
                      className="upload-file"
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                  <span>
                    {file ? (
                      <a href="#" className="uploaded-file-link">
                        {file.name}
                      </a>
                    ) : (
                      <a href="#" className="uploaded-file-link">
                        {formData.IconUrl}
                      </a>
                    )}
                    {/* {file && <button onClick={clearFile} >&times;</button>} */}
                    {file ? (
                      
                        <CloseIcon  className="closeUpload cursor"
                        onClick={clearFile} />
                    ) : formData.IconUrl ? (
                     
                        <CloseIcon className="closeUpload cursor"
                        onClick={clearFileEdit} />
                    ) : null}
                  </span>
                </div>
                {error && <div className="imgError">{error}</div>}
                <p className="imgInfo">
                  [Recommended for this image pixel width(200) x height(150)]
                </p>
              </div>
        </div>
        <div className="example-container m-b">
          <ReactQuill   theme="snow"  className="quill-editor multiSelectWidth"  value={formData.Description ? formData.Description :''} onChange={handleChange1} modules={{
          toolbar: toolbarOptions, // Pass the custom toolbar options
        }} placeholder={"Write something"} />
            </div>
            <div className="example-container m-b">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CategoriesEdit;
