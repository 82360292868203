import React, { useState,useEffect } from 'react';
import leftArrow from './../../../../src/images/left-arrow.svg';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel,IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from '../../../Services/ApiService';

const NewConnectionModelAdd = ({ onCancel }) => {
  
  const [productData, setProductData] = useState(null);
  
  const fetchData = async () => {
    const filterData = {
     "Index": 0,
     "Limit": 100000,
     "OrderByTable": "",
     "OrderByField": "",
     "Sort": "",
     "Aggregator": "",
     "Conditions": [
       
     ]
   }
         const response = ApiService.post('sdmproduct/list', filterData)
         .then(response => {
          setProductData(response.SdMProduct)
             
         })
         .catch(error => {
           console.error('Error:', error);
           setLoading(false);
         });
        
       }
       useEffect(() => {

        fetchData();
      
      }, []);
    const [formData, setFormData] = useState({
        BoxType: '',
        Description:'',
        LogoUrl:'',
        ProductId:'',
        BoxName:'',
        Price:'',
        CpePrice:'',
        GstPrice:'',
        TotalPrice:'',
        SdMProduct:'',
        IsActive: 'Active',
      });
  const [loading, setLoading] = useState(true);
    
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = sessionStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'ProductId'){
          const productDataById = productData.find((type) => type.Id === value);
          setFormData((prevData) => ({
            ...prevData,
            "SdMProduct":{
              Id:productDataById.Id,
              ProductName:productDataById.ProductName
            }
          }));
          
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        const updatedFormData = {
            ...formData,
            CreatedOn: getCurrentDateTime(),
            CreatedBy:userData.Id,
            IsActive:formData.IsActive == "Active" ? true : false,
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.post("sdmboxtype", updatedFormData);
            if (res) {
              const uploadedImages = {
                'Id': res.PrimaryKey,
                'FilePath':file
              }
              try{
              const imgApi = await ApiService.put('sdmboxtype/uploadimage',uploadedImages) 
              // if(imgApi){
              handleCancel();
              // }
              } catch (error){
            console.error("Error updating toggle state:", error);
              }
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const handleKeyDown = (e) => {
        const keynum = e.which || e.keyCode;
      
        // Allow backspace, tab, and enter keys
        if (keynum === 8 || keynum === 9 || keynum === 13) {
          return;
        }
      
        // Allow only numeric characters and dot
        if (
          (keynum < 48 || keynum > 57) && // numeric characters
          (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
        ) {
          e.preventDefault();
        }
      };
      const [file, setFile] = useState(null);
      const [error, setError] = useState(null);
    
      const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          if (selectedFile.size > 2 * 1024 * 1024) {
            setError('Image size should not exceed 2MB.');
            setFile(null); // Clear the file state
          } else if (!['image/jpeg', 'image/png', 'image/bmp', 'image/gif'].includes(selectedFile.type)) {
            setError('Only JPG, JPEG, PNG, BMP, and GIF formats are supported.');
            setFile(null); // Clear the file state
          } else {
            setFile(selectedFile);
            setFormData((prevData) => ({
              ...prevData,
              LogoUrl: selectedFile.name,
            }));
        // console.log(formData.LogoUrl)
            setError(null); // Clear any existing error
            // onFileSelected(selectedFile);
          }
        }
      };
      useEffect(() => {
        console.log(formData.LogoUrl)
      }, [formData.LogoUrl]);
      const clearFile = () => {
        setFile(null);
        setError(null);
      };
      
  return (
    <div className='mainContainer'>
     <div class="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Create Box type</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Box Type"
              name="BoxType"
              value={formData.BoxType}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Box Name"
              name="BoxName"
              value={formData.BoxName}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="Price"
              name="Price"
              value={formData.Price}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          
        </div>
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="CPE Price"
              name="CpePrice"
              value={formData.CpePrice}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="GST Price"
              name="GstPrice"
              value={formData.GstPrice}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container">
            <TextField
              label="TotalPrice"
              name="TotalPrice"
              value={formData.TotalPrice}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
        </div>
        <div className="displayFlex">
       
          <div className="example-container">
            <FormControl>
              <InputLabel>Product Name</InputLabel>
              <Select
                name="ProductId"
                value={formData.ProductId}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {productData !== null ? (
      productData.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.ProductName}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>
          <div className="example-container p-b-29">
      <div className="checkbox-lable m-24">
        Logo
        <span className="cursor imgInfoIcon">
        <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
              <InfoIcon className="Icon-style svg-info " />
        </Tooltip>
        </span>
      </div>
      <div className="disflex">
        <div>
          <label htmlFor="fileInput" className="upload-file-label">Choose Files</label>
          <input 
            className="upload-file" 
            type="file" 
            id="fileInput" 
            onChange={handleFileChange} 
          />
        </div>
        <span>
          {file && <a href="#" className="uploaded-file-link">{file.name}</a>}
          {/* {file && <button onClick={clearFile} >&times;</button>} */}
          {file && 
              <CloseIcon className="closeUpload cursor" onClick={clearFile} />
            }
        </span>     
      </div>
      {error && <div className="imgError">{error}</div>}
      <p className="imgInfo">[Recommended for this image pixel width(200) x height(150)]</p>
    </div>

        </div>
        <div className="example-container m-b">
            <TextField
              label="Description"
              name="Description"
              value={formData.Description}
              onChange={handleChange}
              required
              maxLength={200}
              className='commonInput multiSelectWidth'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
        <div className="example-container m-b">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Save</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default NewConnectionModelAdd;
