import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Signin } from './pages';
import Dashboard from './containers/Dashboard'
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
import ApiService from './Services/ApiService';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Inter, sans-serif', // Specify Inter as the primary font
  },
});

const App = () =>{
  const { enqueueSnackbar } = useSnackbar();

  ApiService.setEnqueueSnackbar(enqueueSnackbar);
    return (
        <BrowserRouter >
        <ThemeProvider theme={theme}>
      <Switch>
        {/* <Route exact path="/404" component={NotFound} />
        <Route exact path="/500" component={BackendError} />
        <Route exact path="/Lockscreen" component={Lockscreen} />
      <Route exact path="/forgot" component={PasswordReset} /> */}
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/" component={Signin} />
        {/* <Route exact path="/signup" component={Signup} /> */}
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
      </ThemeProvider>
    </BrowserRouter>
    )
}

export default App;