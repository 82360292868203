import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import "./../../../../src/styles/common.css"; // Import common styles
import { DialogBox } from "../../Dialog Box";
import ApiService from "../../../Services/ApiService";
import { SnackbarProvider, useSnackbar } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {  Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GenrePriority = ({
  onCancel,
  name,
  dataSource,
  apiUrl,
  priorityApiUrl,
  tableName,
}) => {
  const [genreList, setGenreList] = useState([]);
  const [channelList, setChannelList] = useState([]);
  const [droppedItem, setDroppedItem] = useState(null); 
  const [oldPriority, setOldPriority] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [dialogData, setDialogData] = useState();
  const [channeldialogData, setChannelDialogData] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [channelDialogOpen, setChannelDialogOpen] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [channeldroppedItem, setChannelDroppedItem] = useState(null); 
  const [channelOldPriority, setChannelOldPriority] = useState(null);
  const [channeldraggedItem, setChannelDraggedItem] = useState(null);
  const[GenreId,setGenreId]=useState()

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    let filterData = {
      Index: 0,
      Limit: 1000000,
      OrderByTable: "SdMGenre",
      OrderByField: "Priority",
      Sort: "",
      Aggregator: "OR",
      Conditions: [
        
      ],
    };

    try {
      const response = await ApiService.post("sdmgenre/list", filterData);
      if (response && response.SdMGenre) {
        setGenreList(response.SdMGenre);
        setSelectedGenre(response.SdMGenre[0]);
        const id = response.SdMGenre[0].Id;
        if (id) {
          ChannelData(id);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ChannelData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/genre/channel/priority",
        id
      );
      if (response) {
        setChannelList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#EE3823",
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const handleDragStart = (event, item) => {
    const oldNo = item.Priority;
    setOldPriority(oldNo);
    setDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, droppedItem) => {
    event.preventDefault();
    setDroppedItem(droppedItem);
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    const oldIndex = genreList.findIndex((item) => item === draggedItem);
    const newIndex = genreList.findIndex((item) => item === droppedItem);
    const newData = [...genreList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, draggedItem);
    setGenreList(newData);
    handleDialogClose();

    const req = {
      // OldPriority: droppedItem.Priority,
      // NewPriority: draggedItem.Priority,
      OldPriority: draggedItem.Priority,
      NewPriority: droppedItem.Priority,
    };
    try {
      const res = await ApiService.put("sdmgenre/priority", req);
      if (res) {
        fetchData()
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleChannel = (params) => {
    if (params) {
      setGenreId(params.Id);
      ChannelData(params.Id);
    }
  };

  const channelhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    console.log(item)
    setChannelOldPriority(oldNo);
    setChannelDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const channelhandleDragOver = (event) => {
    event.preventDefault();
  };

  const channelhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setChannelDroppedItem(droppedItem);
    channelhandleDialogOpen();
  };

  const channelhandleDialogOpen = () => {
    setChannelDialogOpen(true);
  };

  const channelhandleDialogClose = () => {
    setChannelDialogOpen(false);
  };
  let channelhandleDialogConfirm = async () => {
    const oldIndex = channelList.findIndex((item) => item === channeldraggedItem);
    const newIndex = channelList.findIndex((item) => item === channeldroppedItem);
    const newData = [...channelList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, channeldraggedItem);
    setChannelList(newData);
    channelhandleDialogClose();
  
    const req = {
      OldPriority: channelOldPriority,
      NewPriority: newIndex + 1,
      PrimaryId:GenreId
    };
    try {
      const res = await ApiService.put("sdmgenre/channelpriority", req);
      if (res) {
        ChannelData(GenreId)
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  return (
    <div>
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={channelDialogOpen}
        onClose={channelhandleDialogClose}
        onConfirm={channelhandleDialogConfirm}
      />
      <div className="mainContainer">
        <div className="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>Genre Priority List</p>
        </div>
        <Box sx={{ width: "100%" }} className="Ptabs">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              indicatorColor="secondary"
              value={value}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#EE3823",
                  color: "#EE3823",
                  height: 3,
                  marginRight: 10,
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Genre Priority" {...a11yProps(0)} />
              <StyledTab label="Genre Channel Priority" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Genre</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={handleDragOver} className="w-100">
                    {genreList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => handleDragStart(e, item)}
                        onDrop={(e) => handleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.Priority}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="w-254">
              {/* <Autocomplete
                value={selectedGenre}
                onChange={(event, newValue) => {
                  setSelectedGenre(newValue);
                  handleChannel(selectedGenre);
                }}
                options={genreList}
                getOptionLabel={(option) => option.PriorityName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Genre List"
                    variant="standard"
                  />
                )}
              /> */}
               <FormControl >
            <InputLabel>Genre List</InputLabel>
            <Select
  value={selectedGenre}
  onChange={(event) => {
    const newValue = event.target.value;
    setSelectedGenre(newValue);
    handleChannel(newValue); // Pass newValue directly to handleChannel
  }}
  required
  className='commonInput'
>
  {genreList.map((status) => (
    <MenuItem key={status.Id} value={status}>{status.PriorityName}</MenuItem>
  ))}
</Select>
          </FormControl>
            </div>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Channel</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={channelhandleDragOver} className="w-100">
                    {channelList && channelList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => channelhandleDragStart(e, item)}
                        onDrop={(e) => channelhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default GenrePriority;