import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import ApiService from "../../../Services/ApiService";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import ReactQuill from "react-quill";

const ContentBlocksEdit = ({ onCancel, dataSource }) => {
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ image: "" }], // Add image button to toolbar
    ["clean"],
  ];

  const [categoryData, setcategoryData] = useState(null);
  const [iconFile, setIconFile] = useState(null); // State for icon file
  const [bannerFile, setBannerFile] = useState(null); // State for banner file
  const [iconError, setIconError] = useState(null);
  const [bannerError, setBannerError] = useState(null);

  const fetchData = async () => {
    const filterData = {
      Index: 0,
      Limit: 100000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    const response = await ApiService.post("sdmcategories/list", filterData);
    setcategoryData(response.SdMCategories);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    Title: dataSource.Title,
    Description: dataSource.Description,
    CategoryId: dataSource.CategoryId,
    BannerImageUrl: dataSource.BannerImageUrl,
    IconUrl: dataSource.IconUrl,
    SourceUrl: dataSource.SourceUrl,
    BlockSlug:dataSource.BlockSlug,
    SdMCategories:dataSource.SdMCategories,
    IsActive: dataSource.IsActive == true ? "Active" : "Inactive",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, []);
  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name === 'CategoryId'){
      const CategoryById = categoryData.find((type) => type.Id === value);
      setFormData((prevData) => ({
        ...prevData,
        "SdMCategories":{
          Id:CategoryById.Id,
          CategoryName:CategoryById.CategoryName
        }
      }));
      
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedInput = formData.Title.toLowerCase().replace(/\s+/g, "-"); // Convert to lowercase and replace spaces with hyphens
    const updatedFormData = {
      ...formData,
      BlockSlug: formattedInput,
      Id: dataSource.Id,
      CreatedOn: dataSource.CreatedOn,
      CreatedBy: dataSource.CreatedBy,
      ModifiedBy: userData.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: formData.IsActive == "Active" ? true : false,
    };
    setFormData(updatedFormData);
    try {
      const res = await ApiService.put("sdmcontentblocks", updatedFormData);
      if (res) {
        const uploadedImages = {
          'Id': dataSource.Id,
          'IconImageFile':iconFile,
          'BannerImageFile':bannerFile
        }
        try{
        const imgApi = await ApiService.put('sdmcontentblocks/uploadimage',uploadedImages) 
        // if(imgApi){
        handleCancel();
        // }
        } catch (error){
      console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    console.log("Form submitted:", updatedFormData);
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleChange1 = (content) => {
    setFormData((prevData) => ({
      ...prevData,
      Description: content,
    }));
  };
  const handleIconFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the icon file
      if (selectedFile.size > 2 * 1024 * 1024) {
        setIconError("Icon size should not exceed 2MB.");
        setIconFile(null);
      } else if (!["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(selectedFile.type)) {
        setIconError("Only JPG, JPEG, PNG, BMP, and GIF formats are supported.");
        setIconFile(null);
      } else {
        setIconFile(selectedFile);
        setIconError(null);
      }
    }
  };

  const handleBannerFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Validate and set the banner file
      if (selectedFile.size > 2 * 1024 * 1024) {
        setBannerError("Banner size should not exceed 2MB.");
        setBannerFile(null);
      } else if (!["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(selectedFile.type)) {
        setBannerError("Only JPG, JPEG, PNG, BMP, and GIF formats are supported.");
        setBannerFile(null);
      } else {
        setBannerFile(selectedFile);
        setBannerError(null);
      }
    }
  };

  const clearIconFile = () => {
    setIconFile(null);
    setIconError(null);
  };

  const clearBannerFile = () => {
    setBannerFile(null);
    setBannerError(null);
  };
  const clearBannerFileEdit = () => {

    setFormData((prevData) => ({
       ...prevData,
       BannerImageUrl: null,
     }));
     setBannerError(null);
   };
   const clearIconFileEdit = () => {

    setFormData((prevData) => ({
       ...prevData,
       IconUrl: null,
     }));
     setIconError(null);
   };
  return (
    <div className="mainContainer">
      <div className="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Edit Content Block</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
      <div className="Content">
          <div className="displayFlex m-b">
            <div className="example-container">
              <TextField
                label="Title"
                name="Title"
                value={formData.Title}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <FormControl>
                <InputLabel>Category</InputLabel>
                <Select
                  name="CategoryId"
                  value={formData.CategoryId}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {categoryData !== null ? (
                    categoryData.map((type) => (
                      <MenuItem key={type.Id} value={type.Id}>
                        {type.CategoryName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">Loading...</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            <div className="example-container p-b-29">
  <div className="checkbox-lable m-24">
    Banner Image
    <span className="cursor imgInfoIcon">
      <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
        <InfoIcon className="Icon-style svg-info " />
      </Tooltip>
    </span>
  </div>
  <div className="disflex">
    <div>
      <label htmlFor="bannerFileInput" className="upload-file-label">
        Choose Files
      </label>
      <input
        className="upload-file"
        type="file"
        id="bannerFileInput"
        onChange={handleBannerFileChange}
      />
    </div>
    <span>
      {bannerFile ? (
        <a href="#" className="uploaded-file-link">
          {bannerFile.name}
        </a>
      ) : (
        <a href="#" className="uploaded-file-link">
          {formData.BannerImageUrl}
        </a>
      )}
      {bannerFile ? (
          <CloseIcon className="closeUpload cursor" onClick={clearBannerFile} />
      ) : formData.BannerImageUrl ? (
          <CloseIcon className="closeUpload cursor" onClick={clearBannerFileEdit} />
      ) : null}
    </span>
  </div>
  {bannerError && <div className="imgError">{bannerError}</div>}
  <p className="imgInfo">
    [Recommended for this image pixel width(200) x height(150)]
  </p>
</div>
          </div>
          <div className="example-container m-b">
            <div className="checkbox-lable m-24">Description</div>
            <ReactQuill
              theme="snow"
              className="quill-editor multiSelectWidth"
              value={formData.Description}
              onChange={handleChange1}
              modules={{
                toolbar: toolbarOptions,
              }}
              placeholder={"Write something"}
            />
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="example-container">
              <TextField
                label="Url"
                name="SourceUrl"
                value={formData.SourceUrl}
                onChange={handleChange}
                required
                maxLength={50}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container p-b-29">
  <div className="checkbox-lable m-24">
    Icon
    <span className="cursor imgInfoIcon">
      <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
        <InfoIcon className="Icon-style svg-info " />
      </Tooltip>
    </span>
  </div>
  <div className="disflex">
    <div>
      <label htmlFor="iconFileInput" className="upload-file-label">
        Choose Files
      </label>
      <input
        className="upload-file"
        type="file"
        id="iconFileInput"
        onChange={handleIconFileChange}
      />
    </div>
    <span>
      {iconFile ? (
        <a href="#" className="uploaded-file-link">
          {iconFile.name}
        </a>
      ) : (
        <a href="#" className="uploaded-file-link">
          {formData.IconUrl}
        </a>
      )}
      {iconFile ? (
          <CloseIcon  className="closeUpload cursor" onClick={clearIconFile} />
      ) : formData.IconUrl ? (
          <CloseIcon className="closeUpload cursor" onClick={clearIconFileEdit} />
      ) : null}
    </span>
  </div>
  {iconError && <div className="imgError">{iconError}</div>}
  <p className="imgInfo">
    [Recommended for this image pixel width(200) x height(150)]
  </p>
</div>
          </div>
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Update
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ContentBlocksEdit;
