import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { TextField as MuiTextField } from '@mui/material/TextField';
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

const ChannelAdd = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const fixedOptions = [];
  const [userData, setUserData] = useState(null);
  const [formData, setFormData] = useState({
    ChannelName: "",
    SystemName: "",
    Poid: "",
    LanguageName:'',
    SmsCode:'',
    Code:'',
    Price:'',
    ShowInAlacarte:false,
    IsSundirectChannel:false,
    LogoUrl:"",
    ShowInFTA:false,
    IsActive: "Active",
    isChecked:false
  });
  const [genreArray, setGenre] = useState([]);
  const [value, setValue] = React.useState([]);
  const [genrevalue, setgenreValue] = React.useState([]);
  const [networkvalue, setnetworkValue] = React.useState([]);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
 

  useEffect(() => {
    getGenreList();
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);

 

  const getGenreList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      const res = await ApiService.post("sdmgenre/list", typeRequest);
      if (res) {
        setGenre(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, [userData]);

 
  const [loading, setLoading] = useState(true);

  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "ShowInAlacarte" || name == "IsSundirectChannel" || name == "ShowInFTA" || name == "isChecked") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(e.target.checked);
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (!file) { // Check if no file is selected
      setError('Channel logo is required.');
      return; // Prevent form submission
    }
    const transformedArray = genrevalue.map(item => ({
      ChannelGenreId:0,
      GenreName: item.GenreName,
      GenreId: item.Id,
      Priority: item.Priority
    }));
      const updatedFormData = {
        ...formData,
        CreatedOn: getCurrentDateTime(),
        CreatedBy: userData.Id,
        IsActive: formData.IsActive == "Active" ? true : false,
        ChannelStatus: "pendingReview",
        SdMGenre:transformedArray,
        ChannelNumber:parseInt(formData.Code),
        LogoUrl:''
      };
      setFormData(updatedFormData);
       try {
        const res = await ApiService.post("sdmchannel", updatedFormData);
        if (res) {
          const uploadedImages = {
            'Id': res.PrimaryKey,
            'FilePath':file
          }
          try{
          const imgApi = await ApiService.put('sdmchannel/uploadimage',uploadedImages) 
          // if(imgApi){
          handleCancel();
    enqueueSnackbar('Added Successfully!',{ variant: 'success' });
          // }
          } catch (error){
        console.error("Error updating toggle state:", error);
          }
        }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Regular expression to match only numeric characters and a single dot
    const isValidInput = /^[0-9.]+$/.test(e.key);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
      e.preventDefault();
    }

    // Prevent input of more than one dot
    const value = e.target.value;
    if ((keynum === 46 || keynum === 190) && value.includes('.')) {
        e.preventDefault();
    }
};
  useEffect(() => {}, [value, genrevalue,networkvalue]);
  // image fields
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError('Image size should not exceed 2MB.');
        setFile(null); // Clear the file state
      } else if (!['image/jpeg', 'image/png', 'image/bmp', 'image/gif'].includes(selectedFile.type)) {
        setError('Only JPG, JPEG, PNG, BMP, and GIF formats are supported.');
        setFile(null); // Clear the file state
      } else {
        setFile(selectedFile);
        setFormData((prevData) => ({
          ...prevData,
          LogoUrl: selectedFile,
        }));
    // console.log(formData.LogoUrl)
        setError(null); // Clear any existing error
        // onFileSelected(selectedFile);
      }
    }
  };
  useEffect(() => {
    console.log(formData.LogoUrl)
  }, [formData.LogoUrl]);
  const clearFile = () => {
    setFile(null);
    setError(null);
  };
  
  return (
    <div className="mainContainer">
      <div class="dis-flex headTitle">
        <img src={leftArrow} alt="" onClick={handleCancel} className="cursor" />
        <p>Create Channel</p>
      </div>
      <form onSubmit={handleSubmit} className="m-t-24">
        <div className="Content">
          <div className="displayFlex m-b">
            <div className="example-container">
              <MaterialUITextField
                label="Name"
                name="ChannelName"
                value={formData.ChannelName}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 50 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MaterialUITextField
                label="System Name"
                name="SystemName"
                value={formData.SystemName}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 50 }}                
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MaterialUITextField
                label="Poid"
                name="Poid"
                value={formData.Poid}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputProps={{ maxLength: 50 }}
                className="commonInput"
                required
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports numbers upto 50 chars.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <MaterialUITextField
                label="Language Name"
                name="LanguageName"
                value={formData.LanguageName}
                onChange={handleChange}
                required
                inputProps={{ maxLength: 50 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MaterialUITextField
                label="Sms Code"
                name="SmsCode"
                value={formData.SmsCode}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required
                inputProps={{ maxLength: 20}}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Combinations of letters and numbers only and upto 20 chars">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
              <MaterialUITextField
                label="Channel Number"
                name="Code"
                value={formData.Code}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputProps={{ maxLength: 20 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Combinations of letters and numbers only and upto 20 chars">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
          </div>
          <div className="displayFlex m-b">
            <div className="example-container">
              <MaterialUITextField
                label="Price"
                name="Price"
                value={formData.Price}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                required
                inputProps={{ maxLength: 6 }}
                className="commonInput"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Value should be decimal. Max 6 numbers allowed">
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={formData.ShowInAlacarte}
                name="ShowInAlacarte"
                onChange={handleChange}
                disabled={parseFloat(formData.Price) === 0} 
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
              Show in A-la-carte
              </div>
            </div>
            <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={formData.IsSundirectChannel}
                name="IsSundirectChannel"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
              Is Sundirect Channel
              </div>
            </div>
          </div>
         
          {genreArray.SdMGenre &&
            genreArray.SdMGenre.length > 0 && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={genrevalue}
                    onChange={(event, newValue) => {
                      setgenreValue(newValue);
                    }}
                    options={genreArray.SdMGenre.filter(
                      (option) =>
                        !value.some((selected) => selected.Id === option.Id)
                    )}
                    getOptionLabel={(option) => option.GenreName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.GenreName}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Genre"
                        placeholder="Genre"
                      variant='standard'
                      />
                    )}
                  />
                </div>
              </div>
            )}
          <div className="displayFlex m-b">
          <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={formData.ShowInFTA}
                name="ShowInFTA"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
             Show In FTA
              </div>
            </div>
            <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={formData.ShowInFTA}
                name="isChecked"
                onChange={handleChange}
                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
             Is Checked
              </div>
            </div>
          <div className="example-container m-b">
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                name="IsActive"
                value={formData.IsActive}
                onChange={handleChange}
                required
                className="commonInput"
              >
                {statusOptions.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.viewValue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          
          </div>
            
          <div className="displayFlex m-b">
        
       
          <div className="example-container p-b-29">
      <div className="checkbox-lable m-24">
        Channel Logo * 
        <span className="cursor imgInfoIcon">
        <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
              <InfoIcon className="Icon-style svg-info " />
        </Tooltip>
        </span>
      </div>
      <div className="disflex">
        <div>
          <label htmlFor="fileInput" className="upload-file-label">Choose Files</label>
          <input 
            className="upload-file" 
            type="file" 
            id="fileInput" 
            onChange={handleFileChange} 
          />
        </div>
        <span>
          {file && <a href="#" className="uploaded-file-link">{file.name}</a>}
          {/* {file && <button onClick={clearFile} >&times;</button>} */}
          {file && 
              <CloseIcon className="closeUpload cursor" onClick={clearFile} />
            }
        </span>     
      </div>
      {error && <div className="imgError">{error}</div>}
      <p className="imgInfo">[Recommended for this image pixel width(200) x height(150)]</p>
    </div>
          </div>
     

     
        </div>
        <div className="submitButton">
          <Button type="submit" className="btn">
            Save
          </Button>
          <Button className="cancelBtn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChannelAdd;
