import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ApiService from "../../../Services/ApiService";
// import Chip from "@mui/material/Chip";
// import Autocomplete from "@mui/material/Autocomplete";
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { TextField as MuiTextField } from '@mui/material/TextField';
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { Loader } from "../../Loader";
const PackageAdd = ({ onCancel }) => {
  const [userData, setUserData] = useState(null);
  const [selectedNetworkChannels, setSelectedNetworkChannels] = useState({});
  const [DayFrom,setDayFrom]=useState(0);
  const [DayTo,setDayTo]=useState(0);
  const handleChangesnetwork = (networkGenreId, value, genreName) => {
    setSelectedNetworkChannels((prevSelectedChannels) => ({
      ...prevSelectedChannels,
      [networkGenreId]: value,
    }));
  };
  const [inactiveDays,setinactiveDays]=useState()
  const [formData, setFormData] = useState({
    PackageName: "",
    Description: "",
    IsNewUser: false,
    IsExistingUser: false,
    IsOfferPack: false,
    IncludeNcf: false,
    NewUserMonthlyPrice: 0,
    ExistingUserMonthlyPrice: 0,
    IsActive: "Active",
  });
  const [ListArray, setListArray] = useState([]);
  const [typeArray, setType] = useState([]);
  const [languageArray, setLanguage] = useState([]);
  const [NetworkArray, setNetwork] = useState([]);
  const [BoxtypeArray, setBoxtype] = useState([]);
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const [isTypeSelectedNetwork, setIsTypeSelectedNetwork] = useState(false);
  const [isAddonsSelected, setIsAddonsSelected] = useState(false);
  const [isNetworkSelected, setIsNetworkSelected] = useState(false);
  const [value, setValue] = React.useState([]);
  const [languagevalue, setlanguageValue] = React.useState();
  const [networkvalue, setnetworkValue] = React.useState();
  const [selectedChannels, setSelectedChannels] = useState({});
  const [selectedGenre, setSelectedGenre] = useState([]);
  const [NetworkAndGenre, setListNetworkGenre] = useState([]);
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState({});
  const [ChannelArray, setChannel] = useState([]);
  const [NetworkChannelArray, setNetworkChannel] = useState([]);
  const [PackageTypeValue, setPackageTypeValue] = useState("");
  const [Channelvalue, setChannelValue] = React.useState([]);
  const [OffersDays, setOffersDays] = React.useState([]);
  const [NetworkChannelvalue, setNetworkChannelValue] = React.useState([]);

  const handleChangesnet = (genreId, value, genreName) => {
    setSelectedGenres((prevSelectedGenres) => ({
      ...prevSelectedGenres,
      [genreId]: value,
    }));
  };
  useEffect(() => {}, [networkvalue]);
  useEffect(() => {
    // getGenreList();
    getChannelList();
    getTypeList();
    getLanguageList();
    // getNetworkList();
    getBoxTypeList();
    const storedData = sessionStorage.getItem("userDetail");

    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setUserData(parsedData);
    }
  }, []);
  const getChannelList = async () => {
    try {
      const res = await ApiService.getAll("common/channellist");
      if (res) {
        setChannel(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getInactiveDaysList = async () => {
    try {
      const res = await ApiService.getAll("sdmpackage/offerpack/days");
      if (res) {
        setOffersDays(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const [networkBasedChannels, setnetworkBasedChannels] = useState([]);
  const [networkChannelData, setnetworkChannelData] = useState([]);
  const getchannelListBasedOnNetwork = async (params) => {
    try {
      const res = await ApiService.getOne("sdmnetwork", params);
      if (res) {
        setnetworkBasedChannels(res.NetworkGenres);
        // console.log(networkBasedChannels)
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {
    // console.log(networkBasedChannels);
    let networkChannelDatas = addGenreIdToChannels(networkBasedChannels);
    setnetworkChannelData(networkChannelDatas);
  }, [networkBasedChannels]);
  const addGenreIdToChannels = (networkBasedChannels) => {
    return networkBasedChannels.map((item) => {
      const channelsWithGenreId = item.NetworkGenreChannel.map((channel) => ({
        ...channel,
        GenreId: item.GenreId,
      }));
      return {
        ...item,
        NetworkGenreChannel: channelsWithGenreId,
      };
    });
  };

  const getGenreList = async () => {
    try {
      setLoading(true);
      const res = await ApiService.getAll("sdmnetwork/genrewithchannels");
      if (res) {
        setListArray(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getTypeList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmpackagetype/list", typeRequest);
      if (res) {
        setType(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getLanguageList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmlanguage/list", typeRequest);
      if (res) {
        setLanguage(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getNetworkList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      setLoading(true);
      const res = await ApiService.post("sdmnetwork/list", typeRequest);
      if (res) {
        setNetwork(res);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getBoxTypeList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      const res = await ApiService.post("sdmboxtype/list", typeRequest);
      if (res) {
        setBoxtype(res.SdMBoxtype);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  useEffect(() => {}, [userData]);

  const handleChanges = (genreId, selectedChannels, genreName) => {
    setSelectedChannels((prevState) => ({
      ...prevState,
      [genreId]: selectedChannels,
    }));
    setSelectedGenre((prevState) => ({
      ...prevState,
      [genreId]: genreName,
    }));
  };

  const [loading, setLoading] = useState();

  const statusOptions = [
    { value: "Active", viewValue: "Active" },
    { value: "Inactive", viewValue: "Inactive" },
  ];
  const InactiveDays = [
    { value: "0-30", viewValue: "0-30 Days" },
    { value: "31-60", viewValue: "31-60 Days" },
    { value: "61-90", viewValue: "61-90 Days" },
    { value: "90+", viewValue: "90+ Days" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name == "IsOfferPack"){
      getInactiveDaysList()
    }
    if (
      name == "IsExistingUser" ||
      name == "IsNewUser" ||
      name == "IsOfferPack" ||
      name == "IncludeNcf" ||
      name == "InactivePack"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.checked,
      }));
      
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if(!formData.IsExistingUser){
      setFormData((prevData) => ({
        ...prevData,
        IsOfferPack: false,
      }));
    }
    
    // console.log(e.target.checked);
  };
  const InactiveDayshandleChange = (e) => {
    const { value } = e.target;
    setDayFrom(value === '0-30' ? 0 : value === '31-60' ? 31 : value === '61-90' ? 61 : value === '90+' ? 90 : 0)
    setDayTo(value === '0-30' ? 30 : value === '31-60' ? 60 : value === '61-90' ? 90 : value === '90+' ? 91 : 0)
  };
  function getCurrentDateTime() {
    let specificValue = [];
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (!formData.IsNewUser && !formData.IsExistingUser) {
      setcheckboxVali(true);
    } else {
      const getpackageTypeId = [PackageTypeValue.Id];
      const getLanguageId = [languagevalue.Id];
      const getNetworkId = networkvalue ? [networkvalue.Id] : [];
      const newUserPriceDetails = inputGroups.map((group) => ({
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input8,
        SystemName:group.input10,
        IsActive: group.input7 === "Active",
        BoxTypeName: group.input9,
        boxId: group.input11
      }));
      const existingUserPriceDetails = inputGroupExisting.map((group) => ({
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input7,
        SystemName:group.input9,
        IsActive: group.input8 === "Active",
        // BoxTypeName: group.input9
      }));
      const Offerpack = inputGroupOffer.map((group) => ({
        OfferPackDaysId:parseFloat(group.input6),
        Description: group.input1,
        Price: parseFloat(group.input2), // Use parseFloat and provide a default value if input is empty
        TotalGstPrice: parseFloat(group.input3),
        NcfPrice: parseFloat(group.input4),
        GstPrice: parseFloat(group.input5),
        // ExcludeNcfPrice: parseFloat(group.input6),
        Poid: group.input7,
        SystemName:group.input9,
        IsActive: group.input8 === "Active",
        // BoxTypeName: group.input9
      }));

      const combined = [];
      Object.entries(selectedChannels).forEach(([genreId, channels]) => {
        const genreName = selectedGenre[genreId];
        const networkGenre = {
          GenreId: parseInt(genreId),
          channelsList: channels.map((channel) => ({
            GenreId: channel.GenreId,
            ChannelId: channel.Id,
          })),
        };
        combined.push(networkGenre);
      });
      setListNetworkGenre(combined);
      // channelvalues
      const transformedArray = Channelvalue.reduce((acc, curr) => {
        // Check if the genre exists in the accumulator array
        const existingGenre = acc.find((item) => item.GenreId === curr.GenreId);
        if (existingGenre) {
          // If the genre exists, add the channel to its NetworkGenreChannel array
          existingGenre.channelsList.push({
            ChannelId: curr.ChannelId,
          });
        } else {
          // If the genre doesn't exist, create a new entry for it in the accumulator array
          acc.push({
            GenreId: curr.GenreId,
            channelsList: [
              {
                ChannelId: curr.ChannelId,
              },
            ],
          });
        }
        return acc;
      }, []);
const mergedArray = existingUserPriceDetails.concat(Offerpack);
      const updatedFormData = {
        ...formData,
        CreatedOn: getCurrentDateTime(),
        CreatedBy: userData.Id,
        IsActive: formData.IsActive == "Active" ? true : false,
        PackageStatus: "pendingReview",
        // genreDetails:
        //   !isNetworkSelected || !getNetworkId ? transformedArray : genreDetails,
        genreDetails: transformedArray,
        PackageTypeId: getpackageTypeId,
        LanguageId: getLanguageId,
        newUserPriceDetails: formData.IsNewUser ? newUserPriceDetails : [],
        existingUserPriceDetails: formData.IsExistingUser
          ? 
          existingUserPriceDetails: [],
        existingUserOfferPriceDetails: formData.IsExistingUser
          ? 
          Offerpack: [],
      };
      setFormData(updatedFormData);
      try {
        const res = await ApiService.post("sdmpackage", updatedFormData);
        if (res) {
          handleCancel();
        }
      } catch (error) {
        console.error("Error updating toggle state:", error);
      }
    }
  };

  const handleCancel = () => {
    // Handle cancel action
    onCancel();
  };
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Regular expression to match only numeric characters and a single dot
    const isValidInput = /^[0-9.]+$/.test(e.key);

    // Prevent input if the pressed key is not valid
    if (!isValidInput) {
      e.preventDefault();
    }

    // Prevent input of more than one dot
    const value = e.target.value;
    if ((keynum === 46 || keynum === 190) && value.includes('.')) {
        e.preventDefault();
    }
};
  useEffect(() => {}, [value, languagevalue, networkvalue]);
  // dynamic fields
  const New = { inputProps: { "aria-label": "New" } };
  const Existing = { inputProps: { "aria-label": "Existing" } };
  const [inputGroups, setInputGroups] = useState([
    {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      input7: "",
      input8: "",
      input9: "",
      input10: "",
      input11: "",
    },
  ]);

  const addInputGroup = () => {
    setInputGroups([
      ...inputGroups,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
        input10: "",
        input11: "",
      },
    ]);
  };

  const removeInputGroup = (index) => {
    const updatedGroups = [...inputGroups];
    updatedGroups.splice(index, 1);
    setInputGroups(updatedGroups);
  };

  const handleInputChange = (index, fieldName, value) => {
    const updatedGroups = [...inputGroups];
    updatedGroups[index][fieldName] = value;
    setInputGroups(updatedGroups);
  };
  const handleInputBoxChange = (index, fieldName, value) => {
    const selectedType = BoxtypeArray.find(type => type.BoxName === value);
    const updatedGroups = [...inputGroups];
    updatedGroups[index][fieldName] = value;
    updatedGroups[index].input11 = selectedType ? selectedType.Id : null;
    setInputGroups(updatedGroups);
  };
  const isInputGroupValidNew = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== "" &&
      group.input10 !== ""
    );
  };

  const [inputGroupExisting, setInputGroupsExisting] = useState([
    {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      // input6: "",
      input7: "",
      input8: "",
      input9: "",
    },
  ]);
  const [inputGroupOffer, setInputGroupsOffer] = useState([
    {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      input5: "",
      input6: "",
      input7: "",
      input8: "",
      input9: "",
    },
  ]);

  const addInputGroupExisting = () => {
    setInputGroupsExisting([
      ...inputGroupExisting,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        // input6: "",
        input7: "",
        input8: "",
        input9: "",
      },
    ]);
  };
  const addInputGroupOffer = () => {
    setInputGroupsOffer([
      ...inputGroupOffer,
      {
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
        input8: "",
        input9: "",
      },
    ]);
  };

  const removeInputGroupExisting = (index) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups.splice(index, 1);
    setInputGroupsExisting(updatedGroups);
  };
  const removeInputGroupOffer = (index) => {
    const updatedGroups = [...inputGroupOffer];
    updatedGroups.splice(index, 1);
    setInputGroupsOffer(updatedGroups);
  };

  const handleInputChangeExisting = (index, fieldName, value) => {
    const updatedGroups = [...inputGroupExisting];
    updatedGroups[index][fieldName] = value;
    setInputGroupsExisting(updatedGroups);
  };
  const handleInputChangeOffer = (index, fieldName, value) => {
    const updatedGroups = [...inputGroupOffer];
    updatedGroups[index][fieldName] = value;
    setInputGroupsOffer(updatedGroups);
  };
  const isInputGroupValid = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== ""
    );
  };
  const isInputGroupValidOffer = (group) => {
    // Check if any of the required fields are empty
    return (
      group.input1 !== "" &&
      group.input2 !== "" &&
      group.input3 !== "" &&
      group.input4 !== "" &&
      group.input5 !== "" &&
      group.input6 !== "" &&
      group.input7 !== "" &&
      group.input8 !== "" &&
      group.input9 !== ""
    );
  };
  const handlePackage = () => {
    if (isNetworkSelected) {
      setChannelValue([]);
      setnetworkChannelData([]);
      setNetworkChannelValue([]);
      setnetworkValue();
      setSelectedNetworkChannels({});
    }
    if (!isNetworkSelected) {
      setChannelValue([]);
    }
  };
  const handlenetwork = (newValue) => {
    setNetworkChannelValue([]);
    if (!newValue) {
      setnetworkChannelData([]);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>Create Package</p>
        </div>
        <form onSubmit={handleSubmit} className="m-t-24">
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Package Name"
                  name="PackageName"
                  value={formData.PackageName}
                  onChange={handleChange}
                  required
                  maxLength={50}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              {languageArray.SdMLanguage &&
                languageArray.SdMLanguage.length > 0 && (
                  <div className="exmple-container">
                    <div className="w254">
                      <Autocomplete
                        id="fixed-tags-demo3"
                        value={languagevalue}
                        onChange={(event, newValue) => {
                          setlanguageValue(newValue);
                        }}
                        options={languageArray.SdMLanguage.filter(
                          (option) =>
                            !value.some((selected) => selected.Id === option.Id)
                        )}
                        getOptionLabel={(option) => option.LanguageName}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              label={option.LanguageName}
                              {...getTagProps({ index })}
                              className="chipsStyle"
                              // disabled={ListArray.indexOf(option) !== -1}
                            />
                          ))
                        }
                        // style={{ width: 858, marginBottom: 24 }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            label="Language"
                            placeholder="Language"
                            required
                            variant="standard"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              {typeArray.SdMPackagetype &&
                typeArray.SdMPackagetype.length > 0 && (
                  <div className="w254">
                    <FormControl>
                      <InputLabel>Package Type</InputLabel>
                      <Select
                        value={PackageTypeValue}
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setPackageTypeValue(newValue);
                        }}
                        required={true}
                        className="commonInput"
                      >
                        {typeArray.SdMPackagetype.map((type) => (
                          <MenuItem
                          value={type}
                          key={type.Id}
                          >{type.PackageType}</MenuItem>
                          ))}
                        {/* {typeArray.SdMPackagetype.filter(
                          (option) => 
                          ).map((status) => (
                          <MenuItem key={status.Id} value={status}>
                            {status.PackageType}
                          </MenuItem>
                        ))} */}
                      </Select>
                    </FormControl>
                  </div>
                )}
            </div>
            <div className="example-container m-b">
              <MaterialUITextField
                className="commonInput multiSelectWidth"
                label="Package Description"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                multiline
                maxLength={100}
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="Supports alphabets, numbers, <Space> & - and + upto 100 chars."
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="exmple-container m-b">
              <div className=" m-24">
                <p className="post">User Type</p>
                {!isAddonsSelected && (
                  <span>
                    <Checkbox
                      checked={formData.IsNewUser}
                      name="IsNewUser"
                      onChange={handleChange}
                      sx={{
                        color: "#EE3823",
                        "&.Mui-checked": {
                          color: "#EE3823",
                        },
                      }}
                    />{" "}
                    New
                  </span>
                )}
                <Checkbox
                  checked={formData.IsExistingUser}
                  name="IsExistingUser"
                  onChange={handleChange}
                  sx={{
                    color: "#EE3823",
                    "&.Mui-checked": {
                      color: "#EE3823",
                    },
                  }}
                />{" "}
                Existing
                <Checkbox
                  checked={!formData.IsExistingUser ? false : formData.IsOfferPack}
                  name="IsOfferPack"
                  onChange={handleChange}
                  disabled={!formData.IsExistingUser}
                  sx={{
                    color: "#EE3823",
                    "&.Mui-checked": {
                      color: "#EE3823",
                    },
                  }}
                />{" "}
                Offer Pack
                {/* Validation error message */}
                {checkboxVali &&
                  !formData.IsExistingUser &&
                  !formData.IsNewUser && (
                    <FormHelperText error>
                      Please select at least one option.
                    </FormHelperText>
                  )}
              </div>
            </div>
            {/* Conditional rendering of input fields */}
            {formData.IsNewUser && (
              <div>
                {/* <div className="example-container m-b">
                  <MaterialUITextField
                    label="New User Monthly Price"
                    name="NewUserMonthlyPrice"
                    value={formData.NewUserMonthlyPrice}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    maxLength={4}
                    className="commonInput"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Supports numbers up to 25 chars.">
                          <InfoIcon className="Icon-style" />
                        </Tooltip>
                      ),
                    }}
                  />
                </div> */}
                <div className="example-container m-b">
                  <p className="post">New User Price List</p>
                  <div>
                    {inputGroups.map((group, index) => (
                      <div key={index} className="input-group">
                        <FormControl required>
                          <InputLabel required>Box Type</InputLabel>
                          <Select
                            name="Box Type"
                            value={group.input9}
                            onChange={(e) =>
                              handleInputBoxChange(index, "input9", e.target.value)
                            }
                            variant="standard"
                            className="commonInput m-48 m-b"
                          >
                            {BoxtypeArray !== null ? (
                              BoxtypeArray.map((type) => (
                                <MenuItem key={type.Id} value={type.BoxName}>
                                  {type.BoxName}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value="">Loading...</MenuItem>
                            )}
                          </Select>
                        </FormControl>

                        <MaterialUITextField
                          label="Description"
                          value={group.input1}
                          onChange={(e) =>
                            handleInputChange(index, "input1", e.target.value)
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Price"
                          value={group.input2}
                          onChange={(e) =>
                            handleInputChange(index, "input2", e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Total GST Price"
                          value={group.input3}
                          onChange={(e) =>
                            handleInputChange(index, "input3", e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="NCF Price"
                          value={group.input4}
                          onChange={(e) =>
                            handleInputChange(index, "input4", e.target.value)
                          }
                          variant="standard"
                          onKeyDown={handleKeyDown}
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="GST Price"
                          value={group.input5}
                          onChange={(e) =>
                            handleInputChange(index, "input5", e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Exclude NCF Price"
                          value={group.input6}
                          onChange={(e) =>
                            handleInputChange(index, "input6", e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="System Name"
                          value={group.input10}
                          onChange={(e) =>
                            handleInputChange(index, "input10", e.target.value)
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="POID"
                          value={group.input8}
                          onChange={(e) =>
                            handleInputChange(index, "input8", e.target.value)
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <FormControl required>
                          <InputLabel required>Status</InputLabel>
                          <Select
                            name="IsActive"
                            value={group.input7}
                            onChange={(e) =>
                              handleInputChange(index, "input7", e.target.value)
                            }
                            className="commonInput m-48 m-b"
                          >
                            {statusOptions.map((status) => (
                              <MenuItem key={status.value} value={status.value}>
                                {status.viewValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          color="secondary"
                          onClick={() => removeInputGroup(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addInputGroup}
                    disabled={
                      inputGroups.length === 0
                        ? false
                        : !isInputGroupValidNew(
                            inputGroups[inputGroups.length - 1]
                          ) ||
                          inputGroups.some(
                            (group) => !isInputGroupValidNew(group)
                          )
                    }
                    className={`btn ${
                      inputGroups.length === 0
                        ? false
                        : !isInputGroupValidNew(
                            inputGroups[inputGroups.length - 1]
                          ) ||
                          inputGroups.some(
                            (group) => !isInputGroupValidNew(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}

            {formData.IsExistingUser && (
              <div>
                {/* <div className="displayFlex m-b">
                  <div className="example-container m-b">
                    <MaterialUITextField
                      label="Existing User Monthly Price"
                      name="ExistingUserMonthlyPrice"
                      value={formData.ExistingUserMonthlyPrice}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      maxLength={4}
                      className="commonInput"
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Supports numbers up to 25 chars.">
                            <InfoIcon className="Icon-style" />
                          </Tooltip>
                        ),
                      }}
                    />
                  </div>
                </div> */}

                <div className="example-container m-b">
                  <p className="post">Existing User Price List</p>
                  <div>
                    {inputGroupExisting.map((group, index) => (
                      <div key={index} className="input-group">
                        <MaterialUITextField
                          label="Description"
                          value={group.input1}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input1",
                              e.target.value
                            )
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Price"
                          value={group.input2}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input2",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Total GST Price"
                          value={group.input3}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input3",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="NCF Price"
                          value={group.input4}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input4",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="GST Price"
                          value={group.input5}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input5",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                       
                        <MaterialUITextField
                          label="System Name"
                          value={group.input9}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input9",
                              e.target.value
                            )
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="POID"
                          value={group.input7}
                          onChange={(e) =>
                            handleInputChangeExisting(
                              index,
                              "input7",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <FormControl required>
                          <InputLabel required>Status</InputLabel>
                          <Select
                            name="IsActive"
                            value={group.input8}
                            onChange={(e) =>
                              handleInputChangeExisting(
                                index,
                                "input8",
                                e.target.value
                              )
                            }
                            className="commonInput m-48 m-b"
                          >
                            {statusOptions.map((status) => (
                              <MenuItem key={status.value} value={status.value}>
                                {status.viewValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          color="secondary"
                          onClick={() => removeInputGroupExisting(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={addInputGroupExisting}
                    disabled={
                      inputGroupExisting.length === 0
                        ? false
                        : !isInputGroupValid(
                            inputGroupExisting[inputGroupExisting.length - 1]
                          ) ||
                          inputGroupExisting.some(
                            (group) => !isInputGroupValid(group)
                          )
                    }
                    className={`btn ${
                      inputGroupExisting.length === 0
                        ? false
                        : !isInputGroupValid(
                            inputGroupExisting[inputGroupExisting.length - 1]
                          ) ||
                          inputGroupExisting.some(
                            (group) => !isInputGroupValid(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
              {(formData.IsExistingUser && formData.IsOfferPack) && (
              <div>

                <div className="example-container m-b">
                  <p className="post">Offer Pack Price List</p>
                  <div>
                    {inputGroupOffer.map((group, index) => (
                      <div key={index} className="input-group">
                        <FormControl>
                      <InputLabel>Inactive Days</InputLabel>
                      <Select
    name="inactiveDays"
    value={group.input6}
    onChange={(e) =>
        handleInputChangeOffer(
            index,
            "input6",
            e.target.value
        )
    }
    required
    className="commonInput m-48 m-b"
>
    {OffersDays && OffersDays.map((day) => (
        <MenuItem 
            key={day.Id} 
            value={day.Id} 

            disabled={
                inputGroupOffer
                    .slice(0, index) // Get all previous groups
                    .some((group) => group.input6 === day.Id) // Check if the value is already selected
            }
        >
            {day.ExpiryDays}
        </MenuItem>
    ))}
</Select>
                    </FormControl>
                        <MaterialUITextField
                          label="Description"
                          value={group.input1}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input1",
                              e.target.value
                            )
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Price"
                          value={group.input2}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input2",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="Total GST Price"
                          value={group.input3}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input3",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="NCF Price"
                          value={group.input4}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input4",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="GST Price"
                          value={group.input5}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input5",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="System Name"
                          value={group.input9}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input9",
                              e.target.value
                            )
                          }
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <MaterialUITextField
                          label="POID"
                          value={group.input7}
                          onChange={(e) =>
                            handleInputChangeOffer(
                              index,
                              "input7",
                              e.target.value
                            )
                          }
                          onKeyDown={handleKeyDown}
                          variant="standard"
                          className="commonInput m-48 m-b"
                          required
                        />
                        <FormControl required>
                          <InputLabel required>Status</InputLabel>
                          <Select
                            name="IsActive"
                            value={group.input8}
                            onChange={(e) =>
                              handleInputChangeOffer(
                                index,
                                "input8",
                                e.target.value
                              )
                            }
                            className="commonInput m-48 m-b"
                          >
                            {statusOptions.map((status) => (
                              <MenuItem key={status.value} value={status.value}>
                                {status.viewValue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <IconButton
                          color="secondary"
                          onClick={() => removeInputGroupOffer(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={addInputGroupOffer}
                    disabled={
                      inputGroupOffer.length === 0
                        ? false
                        : !isInputGroupValidOffer(
                            inputGroupOffer[inputGroupOffer.length - 1]
                          ) ||
                          inputGroupOffer.some(
                            (group) => !isInputGroupValidOffer(group)
                          )
                    }
                    className={`btn ${
                      inputGroupOffer.length === 0
                        ? false
                        : !isInputGroupValidOffer(
                            inputGroupOffer[inputGroupOffer.length - 1]
                          ) ||
                          inputGroupOffer.some(
                            (group) => !isInputGroupValidOffer(group)
                          )
                        ? "disabled"
                        : ""
                    }`}
                  >
                    Add
                  </Button>
                </div>
              </div>
            )}
            {!isNetworkSelected && ChannelArray && ChannelArray.length > 0 && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="fixed-tags-demo3"
                    value={Channelvalue}
                    onChange={(event, newValue) => {
                      setChannelValue(newValue);
                    }}
                    options={ChannelArray.filter(
                      (option) =>
                        !value.some((selected) => selected.Id === option.Id)
                    )}
                    getOptionLabel={(option) => option.ChannelName}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.ChannelName}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                          // disabled={ListArray.indexOf(option) !== -1}
                        />
                      ))
                    }
                    style={{ width: 858, marginBottom: 24 }}
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label={"Channels ("+ Channelvalue.length+ ")"}
                        placeholder="Channels"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
            )}

            <div className="example-container m-b">
              <FormControl>
                <InputLabel>Status</InputLabel>
                <Select
                  name="IsActive"
                  value={formData.IsActive}
                  onChange={handleChange}
                  required
                  className="commonInput"
                >
                  {statusOptions.map((status) => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.viewValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="submitButton">
            <Button type="submit" className="btn">
              Save
            </Button>
            <Button className="cancelBtn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PackageAdd;
