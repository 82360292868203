import React, { useState,useEffect} from 'react';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ApiService from '../../../Services/ApiService';
import leftArrow from './../../../../src/images/left-arrow.svg';
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';

const InstallationDaysEdit = ({ onCancel,dataSource }) => {
  const [state, setState] = useState([]);
  const [stateValue,setstateValue]=useState('');
  const [cityValue,setcityValue]=useState([]);
  const [cityData, setCityData]= useState();
    const [formData, setFormData] = useState({
      City: dataSource.City,
      CityId:dataSource.CityId,
      InstallationDays:dataSource.InstallationDays,
        IsActive: dataSource.IsActive == true ? "Active" : "Inactive" ,
      });
  const [roleOptions, setroleOptions] = useState(null);
  const [loading, setLoading] = useState(true);

      const fetchData = async () => {
    try {
      const response = await ApiService.getAll('common/state');
      setState(response);
    } catch (error) {
      console.error('Error fetching state data:', error);
      setLoading(false);
    }
       
      }
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = sessionStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      useEffect(() => {
  
        fetchData();
      
      }, []);
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Form submitted:', formData);
        const updatedFormData = {
            ...formData,
            Id:dataSource.Id,
            CreatedOn: dataSource.CreatedOn,
            CreatedBy: dataSource.CreatedBy,
            ModifiedBy:userData.Id,
            ModifiedOn:getCurrentDateTime(),
            IsActive:formData.IsActive == "Active" ? true : false,
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.put("sdminstallationdays", updatedFormData);
            if (res) {
                handleCancel()
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const handleKeyDown = (e) => {
        const keynum = e.which || e.keyCode;
      
        // Allow backspace, tab, and enter keys
        if (keynum === 8 || keynum === 9 || keynum === 13) {
          return;
        }
      
        // Allow only numeric characters and dot
        if (
          (keynum < 48 || keynum > 57) && // numeric characters
          (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
        ) {
          e.preventDefault();
        }
      };
      const getstate = async (e)=>{
        setstateValue(e.target.value)
        try {
          const response = await ApiService.getOne('common/city',e.target.value);
          setcityValue(response);
          console.log(cityValue)
        } catch (error) {
          console.error('Error fetching state data:', error);
          setLoading(false);
        }
      }
      const getCity = async (e)=>{
        const selectedCity = e.target.value;
        setCityData(selectedCity); // Set the selected city to the state
        setFormData((prevData) => ({
          ...prevData,
          'CityId': selectedCity.Id,
          'City':selectedCity.Name
        })) 
      }
      useEffect(() => {

      
      }, [cityValue]);
  return (
    <div className='mainContainer'>
     <div className="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Edit Installation Days</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
    <div className="Content">
        <div className="displayFlex m-b">
        <div className="example-container">
            <FormControl>
              <InputLabel>State</InputLabel>
              <Select
                value={stateValue}
                onChange={(e) => getstate(e)}
                required
              className='commonInput'
              >
                { state!== null ? (
      state.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.Name}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>
          <div className="example-container">
            <FormControl>
              <InputLabel>City</InputLabel>
              <Select
                value={cityData}
                onChange={(e) => getCity(e)}
                required
              className='commonInput'
              >
                { cityValue.length !== 0 ? (
      cityValue.map((type) => (
        <MenuItem key={type.Id} value={type}>{type.Name}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Please select state..</MenuItem>
    )}
              </Select>
            </FormControl>
          </div>

          <div className="example-container">
            <TextField
              label="Installation Days"
              name="InstallationDays"
              value={formData.InstallationDays}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              required
              maxLength={2}
              error={formData.InstallationDays && (parseInt(formData.InstallationDays) < 1 || parseInt(formData.InstallationDays) > 30)}
              helperText={formData.InstallationDays && (parseInt(formData.InstallationDays) < 1 || parseInt(formData.InstallationDays) > 30) && 'Installation Days must be between 1 and 30.'}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports only numbers upto 2 chars">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
        </div>
        <div className="example-container m-b">
          <FormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Update</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default InstallationDaysEdit;
