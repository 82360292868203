import React, { useState,useEffect } from 'react';
import { TextField, Button, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ApiService from '../../../Services/ApiService';
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';
// import FormControl from '@mui/joy/FormControl';
// import FormLabel from '@mui/joy/FormLabel';
// import Textarea from '@mui/joy/Textarea';
import './../../../styles/common.css'
const SystemConfiguration = () => {
  const { enqueueSnackbar } = useSnackbar();

  // const classes = useStyles();

const[getData,setData]=useState({})
const [userData, setUserData] = useState(null);
const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    WebsiteName: '',
    FromName: '',
    FromEmail: '',
    AdminEmail: '',
    GoogleManagerTag:'',
    SeoMetaTitle: '',
    SeoMetaKeywords: '',
    SeoMetaDescription: '',
    SmdInstagram: '',
    SmdFacebook: '',
    SmdTwitter: '',
    SmdYoutube: '',
    SmdWhatsAppNo:9677671237,
    SmdWhatsAppUrl:""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // if(name === 'SmdWhatsAppNo'){
    //   setError(!/^\+?[1-9]\d{9,14}$/.test(value));
    // }
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  function getCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if(!error){
      const updatedFormData = {
        ...formData,
        ModifiedBy: userData.Id,
        ModifiedOn:getCurrentDateTime(),
      };
      setFormData(updatedFormData);
      console.log(updatedFormData)
      try {
        const response = await ApiService.put('sdmconfiguration', updatedFormData);
        // setFormData(response);
      enqueueSnackbar('System Configuration Updated Successfully!',{ variant: 'success' });
      } catch (error) {
        console.error('Error:', error);
      }
      console.log('Form submitted:', formData);
    }
    
  };
  useEffect(() => {
    const fetchData = () => {
      ApiService.getOne('sdmconfiguration', 2)
        .then(response => {
          setFormData(response);
          console.log(response); // Use response instead of formData here
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };
  
    const storedData = sessionStorage.getItem('userDetail');
  
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUserData(parsedData);
    }
  
    fetchData();
  }, []); // empty dependency array
  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Regular expression to match only numeric characters and common special characters in mobile numbers
    const isValidInput = /^[0-9+\-()]+$/.test(e.key);
  
    // Prevent input if the pressed key is not valid
    // if (!isValidInput) {
    //   e.preventDefault();
    // }
  };
  return (
    <div>
      <div className="mainContainer">
        <p className="headTitlecon">System Configuration</p>
        <form onSubmit={handleSubmit}>
          <div className="Contentcon">
            <p className="contentHead">Website</p>
            {/* <div className="example-container m-t-10">
                <TextField
                  label="Name"
                  name="WebsiteName"
                  value={formData.WebsiteName}
                  onChange={handleChange}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Website Name" placement="top">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div> */}
            <div className="textareaCls">
              <label className="post">
                Name *
                <Tooltip className='infopostion' title="Website Name" placement="top">
                  <InfoIcon className="Icon-style" />
                </Tooltip>
              </label>
              <textarea
                name="WebsiteName"
                onChange={handleChange}
                required
                className="multiSelectWidth"
                value={formData.WebsiteName}
                style={{ minHeight: '64px' }}
              />
            </div>
            <div className="textareaCls">
              <label className="post">Google Manager Tag *</label>
              <textarea
                name="GoogleManagerTag"
                onChange={handleChange}
                required
                className="multiSelectWidth"
                value={formData.GoogleManagerTag}
                style={{ minHeight: '64px' }}
              />
            </div>
          </div>
          <div className="line"></div>
          <div className="Contentcon">
            <p className="contentHead">Mail</p>
            <div className="displayFlex">
              <div className="example-container m-t-10">
                <TextField
                  label="From Name"
                  name="FromName"
                  value={formData.FromName}
                  onChange={handleChange}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="From name that appear on the Emails triggered from website"
                        placement="top"
                      >
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container m-t-10">
                <TextField
                  label="From Email"
                  name="FromEmail"
                  value={formData.FromEmail}
                  onChange={handleChange}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="From name that appear on the Emails triggered from website"
                        placement="top"
                      >
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                  error={
                    formData.FromEmail &&
                    !/^\S+@\S+\.\S+$/.test(formData.FromEmail)
                  }
                  helperText={
                    formData.FromEmail &&
                    !/^\S+@\S+\.\S+$/.test(formData.FromEmail) &&
                    "Please enter a valid email address."
                  }
                />
              </div>
              <div className="example-container m-t-10">
                <TextField
                  label="Admin Email"
                  name="AdminEmail"
                  value={formData.AdminEmail}
                  onChange={handleChange}
                  required
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Admin email" placement="top">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                  error={
                    formData.AdminEmail &&
                    !/^\S+@\S+\.\S+$/.test(formData.AdminEmail)
                  }
                  helperText={
                    formData.AdminEmail &&
                    !/^\S+@\S+\.\S+$/.test(formData.AdminEmail) &&
                    "Please enter a valid email address."
                  }
                />
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="Contentcon">
            <p className="contentHead">SEO Details</p>
            {/* <div className="example-container m-b-20 m-t-10">
              <TextField
                label="Meta Title"
                name="SeoMetaTitle"
                value={formData.SeoMetaTitle}
                onChange={handleChange}
                required
                className="commonInput m-48 multiSelectWidth sm-b"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta title when page specific meta title is not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div> */}
            <div className="textareaCls">
              <label className="post">
                Meta Title *
                <Tooltip className='infopostion' title="SEO-Default meta title when page specific meta title is not available this is considered" placement="top">
                  <InfoIcon className="Icon-style" />
                </Tooltip>
              </label>
              <textarea
                name="SeoMetaTitle"
                onChange={handleChange}
                required
                className="multiSelectWidth"
                value={formData.SeoMetaTitle}
                style={{ minHeight: '64px' }}
              />
            </div>
            {/* <div className="example-container m-b-20 m-t-10">
              <TextField
                label="Meta Keywords"
                name="SeoMetaKeywords"
                value={formData.SeoMetaKeywords}
                onChange={handleChange}
                required
                className="commonInput m-48 multiSelectWidth sm-b"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta keywords when page specific keywordss not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div> */}
            <div className="textareaCls">
              <label className="post">
                  Meta Keywords *
                <Tooltip className='infopostion' title="SEO-Default meta keywords when page specific keywordss not available this is considered" placement="top">
                  <InfoIcon className="Icon-style" />
                </Tooltip>
              </label>
              <textarea
                name="SeoMetaKeywords"
                onChange={handleChange}
                required
                className="multiSelectWidth"
                value={formData.SeoMetaKeywords}
                style={{ minHeight: '64px' }}
              />
            </div>
            {/* <div className="example-container m-b-20 m-t-10">
              <TextField
                label="Meta Description"
                name="SeoMetaDescription"
                value={formData.SeoMetaDescription}
                onChange={handleChange}
                required
                className="commonInput m-48 multiSelectWidth sm-b"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta description when page specific description is not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div> */}
            <div className="textareaCls">
              <label className="post">
              Meta Description *
                <Tooltip className='infopostion' title="SEO-Default meta description when page specific description is not available this is considered" placement="top">
                  <InfoIcon className="Icon-style" />
                </Tooltip>
              </label>
              <textarea
                name="SeoMetaDescription"
                onChange={handleChange}
                required
                className="multiSelectWidth"
                value={formData.SeoMetaDescription}
                style={{ minHeight: '64px' }}
              />
            </div>
          </div>
          <div className="line"></div>
          <div className="Contentcon">
            <p className="contentHead">Social Media Details</p>
            <div className="example-container m-t-10">
              <TextField
                label="Instagram"
                name="SmdInstagram"
                value={formData.SmdInstagram}
                onChange={handleChange}
                required
                className="commonInput m-48 m-b"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta title when page specific meta title is not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
              <TextField
                label="Facebook"
                name="SmdFacebook"
                value={formData.SmdFacebook}
                onChange={handleChange}
                required
                className="commonInput m-48"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta keywords when page specific keywordss not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
              <TextField
                label="Twitter"
                name="SmdTwitter"
                value={formData.SmdTwitter}
                onChange={handleChange}
                required
                className="commonInput m-48"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta description when page specific description is not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
              <TextField
                label="Youtube"
                name="SmdYoutube"
                value={formData.SmdYoutube}
                onChange={handleChange}
                required
                className="commonInput m-48"
                InputProps={{
                  endAdornment: (
                    <Tooltip
                      title="SEO-Default meta description when page specific description is not available this is considered"
                      placement="top"
                    >
                      <InfoIcon className="Icon-style" />
                    </Tooltip>
                  ),
                }}
              />
            </div>
            <div className="displayFlex">
              <div className="example-container m-t-10">
                <TextField
                  label="WhatsApp"
                  name="SmdWhatsAppUrl"
                  value={formData.SmdWhatsAppUrl}
                  onChange={handleChange}
                  required
                  onKeyDown={handleKeyDown}
                  // error={error}
                  // helperText={error ? "Please enter a valid phone number" : ""}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Enter WhatsApp number" placement="top">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="submitButton subCon">
            <Button type="submit" className="btn">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SystemConfiguration;