import React, { useState, useEffect } from "react";
import leftArrow from "./../../../../src/images/left-arrow.svg";
import "./../../../../src/styles/common.css"; // Import common styles
import { DialogBox } from "../../Dialog Box";
import ApiService from "../../../Services/ApiService";
import { SnackbarProvider, useSnackbar } from "notistack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import { Loader } from '../../Loader';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const bouquetPagePriority = ({
  onCancel,
  name,
  dataSource,
  apiUrl,
  priorityApiUrl,
  tableName,
}) => {
  const [loading, setLoading] = useState(false);
  const [genreList, setGenreList] = useState([]);
  const [newbouquetList, setnewbouquetList] = useState([]);
  const [exisbouquetList, setexisbouquetList] = useState([]);
  const [bouquetList, setbouquetList] = useState([]);
  const [bouquetGenreList, setbouquetGenreList] = useState([]);
  const [bouquetGenreChannelList, setbouquetGenreChannelList] = useState([]);

  const [bouquetChannelList, setbouquetChannelList] = useState([]);
  const [newdroppedItem, setnewDroppedItem] = useState(null);
  const [newoldPriority, setnewOldPriority] = useState(null);
  const [newdraggedItem, setnewDraggedItem] = useState(null);
  const [newdialogOpen, setnewDialogOpen] = useState(false);
  const [exisdroppedItem, setexisDroppedItem] = useState(null);
  const [exisoldPriority, setexisOldPriority] = useState(null);
  const [exisdraggedItem, setexisDraggedItem] = useState(null);
  const [exisdialogOpen, setexisDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState();
  const [bouquetGenredialogData, setbouquetGenreDialogData] = useState();
  const [bouquetGenreDialogOpen, setbouquetGenreDialogOpen] = useState(false);
  const [bouquetGenreChannelDialogOpen, setbouquetGenreChannelDialogOpen] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedchannalgenre, setselectedchannalgenre] = useState(null);
  const [bouquetGenredroppedItem, setbouquetGenreDroppedItem] = useState(null);
  const [bouquetGenreOldPriority, setbouquetGenreOldPriority] = useState(null);
  const [bouquetGenredraggedItem, setbouquetGenreDraggedItem] = useState(null);
  const [bouquetGenreChanneldroppedItem, setbouquetGenreChannelDroppedItem] = useState(null);
  const [bouquetGenreChannelOldPriority, setbouquetGenreChannelOldPriority] = useState(null);
  const [bouquetGenreChanneldraggedItem, setbouquetGenreChannelDraggedItem] = useState(null);
  const [newbouquetId,setnewbouquetId]=useState();
  const [exisbouquetId,setexisbouquetId]=useState();
  const [bouquetGenreId,setbouquetGenreId]=useState();
const [currentbouquetId,setcurrentbouquetId]=useState();
const [currentGenreId,setcurrentGenreId]=useState();

  useEffect(() => {
    fetchData();
    bouquetfetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await ApiService.getAll(
        "common/bouquet/priority"
      );
      if (response) {
        setnewbouquetList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const bouquetGenreData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/bouquet/genere/priority",
        id
      );
      if (response) {
        setbouquetGenreList(response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const bouquetChannelData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/bouquet/genere/priority",
        id
      );
      if (response) {
        setbouquetChannelList(response);
        console.log(response)
        setselectedchannalgenre(response[0])
        bouquetGenreChannelData(response[0].GenreId);
        setcurrentGenreId(response[0].GenreId)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const bouquetGenreChannelData = async (id) => {
    try {
      const response = await ApiService.getOne(
        "common/bouquet/channel/priority",
        id
      );
      if (response) {
        setbouquetGenreChannelList(response);
        // console.log(response)
        // setselectedchannalgenre(response[0])
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const StyledTab = styled(Tab)({
    "&.Mui-selected": {
      color: "#EE3823",
    },
  });

  const handleCancel = () => {
    onCancel();
  };

  const newhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    setnewOldPriority(oldNo);
    setnewDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const newhandleDragOver = (event) => {
    event.preventDefault();
  };

  const newhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setnewDroppedItem(droppedItem);
    setnewbouquetId(droppedItem)
    newhandleDialogOpen();
  };

  const newhandleDialogOpen = () => {
    setnewDialogOpen(true);
  };

  const newhandleDialogClose = () => {
    setnewDialogOpen(false);
  };

  const newhandleDialogConfirm = async () => {
    const oldIndex = newbouquetList.findIndex((item) => item === newdraggedItem);
    const newIndex = newbouquetList.findIndex((item) => item === newdroppedItem);
    const newData = [...newbouquetList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, newdraggedItem);
    setnewbouquetList(newData);
    newhandleDialogClose();

    const req = {
      OldPriority: newoldPriority,
      NewPriority: newIndex + 1,
      PrimaryId:newbouquetId.bouquetId
    };
    try {
      const res = await ApiService.put("sdmbouquet/priority", req);
      if (res) {
        fetchData()
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const exishandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    setexisOldPriority(oldNo);
    setexisDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const exishandleDragOver = (event) => {
    event.preventDefault();
  };

  const exishandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setexisDroppedItem(droppedItem);
    setexisbouquetId(droppedItem);
    console.log(droppedItem)
    exishandleDialogOpen();
  };

  const exishandleDialogOpen = () => {
    setexisDialogOpen(true);
  };

  const exishandleDialogClose = () => {
    setexisDialogOpen(false);
  };



  const handlebouquetGenre = (params) => {
    
    if (params) {
        setcurrentbouquetId(params.Id)
        bouquetGenreData(params.Id);
    }
  };
  const handleChannelGenre = (params) => {
    
    if (params) {
        setcurrentbouquetId(params.Id)
        bouquetChannelData(params.Id);
    }
  };
  const handleGenreBasedChannel = (params)=>{
    if (params) {
        setcurrentGenreId(params.GenreId)
        bouquetGenreChannelData(params.GenreId);
    }
  }

  const bouquetGenrehandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    console.log(item);
    setbouquetGenreOldPriority(oldNo);
    setbouquetGenreDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const bouquetGenrehandleDragOver = (event) => {
    event.preventDefault();
  };

  const bouquetGenrehandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setbouquetGenreDroppedItem(droppedItem);
    setbouquetGenreId(droppedItem);
    console.log(droppedItem)
    bouquetGenrehandleDialogOpen();
  };

  const bouquetGenrehandleDialogOpen = () => {
    setbouquetGenreDialogOpen(true);
  };

  const bouquetGenrehandleDialogClose = () => {
    setbouquetGenreDialogOpen(false);
  };
  let bouquetGenrehandleDialogConfirm = async () => {
    const oldIndex = bouquetGenreList.findIndex(
      (item) => item === bouquetGenredraggedItem
    );
    const newIndex = bouquetGenreList.findIndex(
      (item) => item === bouquetGenredroppedItem
    );
    const newData = [...bouquetGenreList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, bouquetGenredraggedItem);
    setbouquetGenreList(newData);
    bouquetGenrehandleDialogClose();

    const req = {
      OldPriority: bouquetGenreOldPriority,
      NewPriority: newIndex + 1,
      PrimaryId: currentbouquetId,
    };
    try {
      const res = await ApiService.put("sdmbouquet/genre/priority", req);
      if (res) {
        bouquetGenreData(currentbouquetId)
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const bouquetGenreChannelhandleDragStart = (event, item) => {
    const oldNo = item.PriorityNo;
    console.log(item);
    setbouquetGenreChannelOldPriority(oldNo);
    setbouquetGenreChannelDraggedItem(item);
    event.dataTransfer.setData("text/plain", "");
  };

  const bouquetGenreChannelhandleDragOver = (event) => {
    event.preventDefault();
  };

  const bouquetGenreChannelhandleDrop = (event, droppedItem) => {
    event.preventDefault();
    setbouquetGenreChannelDroppedItem(droppedItem);
    setbouquetGenreId(droppedItem);
    console.log(droppedItem)
    bouquetGenreChannelhandleDialogOpen();
  };

  const bouquetGenreChannelhandleDialogOpen = () => {
    setbouquetGenreChannelDialogOpen(true);
  };

  const bouquetGenreChannelhandleDialogClose = () => {
    setbouquetGenreChannelDialogOpen(false);
  };
  let bouquetGenreChannelhandleDialogConfirm = async () => {
    const oldIndex = bouquetGenreChannelList.findIndex(
      (item) => item === bouquetGenreChanneldraggedItem
    );
    const newIndex = bouquetGenreChannelList.findIndex(
      (item) => item === bouquetGenreChanneldroppedItem
    );
    const newData = [...bouquetGenreChannelList];
    newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, bouquetGenreChanneldraggedItem);
    setbouquetGenreChannelList(newData);
    bouquetGenreChannelhandleDialogClose();

    const req = {
      OldPriority: bouquetGenreChannelOldPriority,
      NewPriority: newIndex + 1,
      PrimaryId: currentGenreId,
    };
    try {
      const res = await ApiService.put("sdmbouquet/channel/priority", req);
      if (res) {
        bouquetGenreChannelData(currentGenreId)
        enqueueSnackbar("Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const bouquetfetchData = async () => {
    let filterData = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "OR",
      Conditions: [],
    };

    try {
        setLoading(true)
      const response = await ApiService.post("sdmbouquet/list", filterData);
      if (response && response.SdMBouquet) {
        setbouquetList(response.SdMBouquet);
        setSelectedGenre(response.SdMBouquet[0]);
        setSelectedChannel(response.SdMBouquet[0])
        console.log(response.SdMBouquet)
        setLoading(false)
        const id = response.SdMBouquet[0].Id;
        if (id) {
            bouquetGenreData(id);
            bouquetChannelData(id)
        }
      }
    } catch (error) {
        setLoading(false)
      console.error("Error:", error);
    }
  };

  return (
    <div>
        {loading && <Loader />}
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={newdialogOpen}
        onClose={newhandleDialogClose}
        onConfirm={newhandleDialogConfirm}
      />
      <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={bouquetGenreDialogOpen}
        onClose={bouquetGenrehandleDialogClose}
        onConfirm={bouquetGenrehandleDialogConfirm}
      />
       <DialogBox
        title={"Confirmation"}
        content={"Are you sure want to re-order the priority?"}
        open={bouquetGenreChannelDialogOpen}
        onClose={bouquetGenreChannelhandleDialogClose}
        onConfirm={bouquetGenreChannelhandleDialogConfirm}
      />
      <div className="mainContainer">
        <div className="dis-flex headTitle">
          <img
            src={leftArrow}
            alt=""
            onClick={handleCancel}
            className="cursor"
          />
          <p>Bouquet Priority List</p>
        </div>
        <Box sx={{ width: "100%" }} className="Ptabs">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              indicatorColor="secondary"
              value={value}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#EE3823",
                  color: "#EE3823",
                  height: 3,
                  marginRight: 10,
                },
              }}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Priority for New User" {...a11yProps(0)} />
              <StyledTab label="bouquet Genre Priority" {...a11yProps(1)} />
              <StyledTab
                label="bouquet Genre Channel Priority"
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">bouquet</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={newhandleDragOver} className="w-100">
                    {newbouquetList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => newhandleDragStart(e, item)}
                        onDrop={(e) => newhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="w-254">
              <FormControl>
                <InputLabel>Bouquet List</InputLabel>
                <Select
                  value={selectedGenre}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setSelectedGenre(newValue);
                    handlebouquetGenre(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {bouquetList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.BouquetName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Genre</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={bouquetGenrehandleDragOver} className="w-100">
                    {bouquetGenreList && bouquetGenreList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => bouquetGenrehandleDragStart(e, item)}
                        onDrop={(e) => bouquetGenrehandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="displayFlex">
            <div className="w-254">
              <FormControl>
                <InputLabel>Bouquet List</InputLabel>
                <Select
                  value={selectedChannel}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setSelectedChannel(newValue);
                    handleChannelGenre(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {bouquetList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.BouquetName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="w-254">
              <FormControl>
                <InputLabel>Genre List</InputLabel>
                <Select
                  value={selectedchannalgenre}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setselectedchannalgenre(newValue);
                    handleGenreBasedChannel(newValue);
                  }}
                  required
                  className="commonInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 500, // Adjust the height as needed
                      },
                    },
                  }}
                >
                  {bouquetChannelList.map((status) => (
                    <MenuItem key={status.Id} value={status}>
                      {status.PriorityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            </div>
          
            <div className="priorityContainer">
              <div className="priorityHeader">
                <div className="priorityTitle">Priority</div>
                <div className="priorityTitle">Genre</div>
              </div>
              <div>
                <div className="priorityContent">
                  <div onDragOver={bouquetGenreChannelhandleDragOver} className="w-100">
                    {bouquetGenreChannelList && bouquetGenreChannelList.map((item, index) => (
                      <div
                      className={`prioritylist ${index % 2 === 0 ? 'even-row' : 'odd-row'}`}
                        key={index}
                        draggable
                        onDragStart={(e) => bouquetGenreChannelhandleDragStart(e, item)}
                        onDrop={(e) => bouquetGenreChannelhandleDrop(e, item)}
                      >
                        <div className="priorityTitle colorText">
                          {item.PriorityNo}
                        </div>
                        <div>{item.PriorityName}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default bouquetPagePriority;
