import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@material-ui/icons/Info";
import leftArrow from "./../../../images/left-arrow.svg";
import { DialogBox } from "../../Dialog Box";

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@mui/material/Autocomplete";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import Chip from "@mui/material/Chip";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import only the necessary Quill styles
import ApiService from "../../../Services/ApiService";
import { TextField as MaterialUITextField } from "@material-ui/core";
import { TextField as MuiTextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";

const ChannelEdit = ({ onCancel, dataSource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],
    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ];

  const formRef = useRef(null);
  // const [genreName, setGenreName] = useState("");
  const [Packagevalue, setPackageValue] = React.useState([]);
  const [GenreArray, setGenre] = useState([]);

  const [ChannelName, setChannelName] = useState("");
  const [SystemName, setSystemName] = useState("");
  const [Poid, setPoid] = useState("");
  const [LanguageName, setLanguageName] = useState("");
  const [SmsCode, setSmsCode] = useState(false);
  const [Code, setCode] = useState("");
  const [Price, setPrice] = useState("");
  const [ShowInAlacarte, setShowInAlacarte] = useState(false);
  const [LogoUrl, setLogoUrl] = useState();
  const [IsSundirectChannel, setIsSundirectChannel] = useState(false);
  const [ShowInFTA, setShowInFTA]=useState(false)
  const [isChecked, setIsChecked]=useState(false)
  const [Priority, setPriority] = useState("");
  const [PriorityName, setPriorityName] = useState("");
  const [ApprovedBy, setApprovedBy] = useState();
  const [ApprovedOn, setApprovedOn] = useState();
  const [IsPublished, setIsPublished] = useState(true);
  const [SdMGenre, setSdMGenre] = useState("");
  const [isActive, setIsActive] = useState("Active");
  const [ChannelStatus, setChannelStatus] = useState("");
  const [comment, setComment] = useState("");
  const [scheduledPublishDt, setScheduledPublishDt] = useState();
  const [isPublishLater, setIsPublishLater] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [publishedby, setPublishedBy] = useState();
  const [publishedon, setPublishedOn] = useState();
  const [selectedPackages, setSelectedPackages] = useState();

  const [userDetails, setUserDetails] = useState(null);
  const [ApproveStatus, setApproveStatus] = useState([
    { value: "pendingReview", viewValue: "Pending Review" },
    { value: "approval", viewValue: "Approval" },
    { value: "rejected", viewValue: "Rejected" },
    { value: "scheduled", viewValue: "Scheduled" },
  ]);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [isPublished, setIsPublised] = useState(true);
  const [postedName, setPostedName] = useState();
  const [checkboxVali, setcheckboxVali] = useState(false);
  const [value, setValue] = React.useState([]);

  useEffect(() => {
    // getEditDetails(rowData);
    getGenreList();
    getData();
    setUserDetails(JSON.parse(sessionStorage.getItem("userDetail")));
    // getUserName()
  }, [dataSource]);
  useEffect(() => {
    if (userDetails && userDetails.Id) {
      getUserName();
    }
  }, [userDetails]);
  const [editData, setEditData] = useState(); // Add this line
  useEffect(() => {
    // This effect runs whenever editData changes
    console.log("Edit Data:", editData);
    // getUserName()
  }, [editData, userDetails]); // Add editData as dependency
  const getGenreList = async () => {
    const typeRequest = {
      Index: 0,
      Limit: 10000,
      OrderByTable: "",
      OrderByField: "",
      Sort: "",
      Aggregator: "",
      Conditions: [],
    };
    try {
      const res = await ApiService.post("sdmgenre/list", typeRequest);
      if (res) {
        setGenre(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  const getData = async () => {
    try {
      const res = await ApiService.getOne("sdmchannel", dataSource);
      if (res) {
        setChannelName(res.ChannelName);
        setSystemName(res.SystemName);
        setPoid(res.Poid);
        setLanguageName(res.LanguageName);
        setSmsCode(res.SmsCode);
        setCode(res.Code);
        setPrice(res.Price);
        // setFile(res.ImageUrl)
        setShowInAlacarte(res.ShowInAlacarte);
        setIsSundirectChannel(res.IsSundirectChannel);
        setShowInFTA(res.ShowInFTA)
        setIsChecked(res.IsDisabled)
        setLogoUrl(res.LogoUrl);
        setPriority(res.Priority);
        setPriorityName(res.PriorityName);
        setIsPublished(res.IsPublished);
        setApprovedBy(res.ApprovedBy);
        setApprovedOn(res.ApprovedOn);

        setSdMGenre(res.SdMGenre);
        setChannelStatus(
          res.ChannelStatus == "Published" ? "approval" : res.ChannelStatus
        );
        setIsActive(res.IsActive == true ? "Active" : "Inactive");
        setComment(res.Comment);
        setScheduledPublishDt(res.ScheduledPublishDt);
        setIsPublishLater(res.IsPublishLater);
        setIsApproved(res.IsApproved);
        setPublishedOn(res.PublishedOn);
        setPublishedBy(res.PublishedBy);
        setSelectedPackages(res.SdMGenre);
        setEditData(res);
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleKeyDown = (e) => {
    const keynum = e.which || e.keyCode;
  
    // Allow backspace, tab, and enter keys
    if (keynum === 8 || keynum === 9 || keynum === 13) {
      return;
    }
  
    // Allow only numeric characters and dot
    if (
      (keynum < 48 || keynum > 57) && // numeric characters
      (keynum !== 46 && keynum !== 190) // dot characters (190 for keypad dot)
    ) {
      e.preventDefault();
    }
  };
  useEffect(() => {}, [Packagevalue]);
  const getCurrentDateTime = () => {
    const currentDate = new Date();
    return currentDate.toISOString();
  };
  const getUserName = async () => {
    try {
      if (userDetails.Id) {
        // Check if userDetails and Id property exist
        const res = await ApiService.getOne("sdmusers", userDetails.Id);
        if (res) {
          console.log(res);
          setPostedName(res.FirstName);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const handleApprovePublish = (e) => {
    e.preventDefault();
      onSubmit();
  };

  const onSubmit = async () => {
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const ProductData = {
      ChannelName: ChannelName,
      SystemName: SystemName,
      Poid: Poid,
      LanguageName: LanguageName,
      SmsCode: SmsCode,
      Code: Code,
      Price: Price,
      ShowInAlacarte: ShowInAlacarte,
      IsSundirectChannel: IsSundirectChannel,
      ShowInFTA:ShowInFTA,
      LogoUrl: LogoUrl,
      Priority: Priority,
      PriorityName: PriorityName,
      IsPublished: IsPublished,
      ApprovedBy: ApprovedBy,
      ApprovedOn: ApprovedOn,
      SdMGenre: selectedPackages.map((selectedPackage) => ({
        GenreId: selectedPackage.GenreId
          ? selectedPackage.GenreId
          : selectedPackage.Id,
        GenreName: selectedPackage.GenreName,
      })),
      ChannelStatus: ChannelStatus,
      IsPublishLater: isPublishLater,
      ScheduledPublishDt: formattedScheduledPublishDt,
      IsActive: isActive == "Active" ? true : false,
      Comment: comment,
      IsApproved: isApproved,
      PublishedOn: publishedon,
      PublishedBy: publishedby,
      Id: editData.Id,
      CreatedBy: editData.CreatedBy,
      CreatedOn: editData.CreatedOn,
      ModifiedBy: userDetails.Id,
      ModifiedOn: getCurrentDateTime(),
      IsActive: isActive == "Active" ? true : false,
      IsDisabled: isChecked,
    };
    console.log(ProductData);
    try {
      const res = await ApiService.put("sdmchannel", ProductData);
      if (res) {
        const uploadedImages = {
          Id: editData.Id,
          FilePath: file,
        };
        try {
          const imgApi = await ApiService.put(
            "sdmchannel/uploadimage",
            uploadedImages
          );
          // if(imgApi){
          // if (isPublished) {
          enqueueSnackbar("Updated Successfully!", { variant: "success" });
            onCancel();
          // }
          // }
        } catch (error) {
          console.error("Error updating toggle state:", error);
        }
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const onPublish = async () => {
    setPublishedOn(getCurrentDateTime());
    setApprovedOn(getCurrentDateTime());
    setApprovedBy(userDetails.Id);
    setPublishedBy(userDetails.Id);
    const formattedScheduledPublishDt = scheduledPublishDt
      ? new Date(scheduledPublishDt).toISOString()
      : null;
    const publishData = {
      Id: editData.Id,
      UserId: editData.CreatedBy,
      Comment: comment,
      IsApproved: isApproved,
      IsPublishedLater: isPublishLater ? isPublishLater : false,
      SchedulePublishedDT: formattedScheduledPublishDt,
    };
    try {
      const res = await ApiService.put("sdmchannel/approve", publishData);
      // if (res) {
        enqueueSnackbar("Published Successfully!", {
          variant: "success",
        });
      onCancel();
      // }
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
    console.log(publishData);
  };

  const onApprovalStatusChange = (val) => {
    setChannelStatus(val);
    if (val != "scheduled") {
      setScheduledPublishDt(null);
    }
    if (val == "approval") {
      setIsApproved(true);
      setIsPublished(true);
    } else {
      setIsApproved(false);
      setIsPublished(false);
    }
    if (val == "scheduled") {
      setIsPublishLater(true);
    } else {
      setIsPublishLater(false);
    }
  };
  const handleDialogClose = () => {
    onSubmit();
    setDialogOpen(false);
  };
  const handleDialogConfirm = async () => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const handlePublish = async (e) => {
    setIsPublished(false);
    await onSubmit();
    onPublish();
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  const formattedDate = formatDate(editData ? editData.CreatedOn : "");
  const ModifiedformattedDate = formatDate(editData?editData.ModifiedOn:'-');
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 2 * 1024 * 1024) {
        setError("Image size should not exceed 2MB.");
        setFile(null); // Clear the file state
      } else if (
        !["image/jpeg", "image/png", "image/bmp", "image/gif"].includes(
          selectedFile.type
        )
      ) {
        setError("Only JPG, JPEG, PNG, BMP, and GIF formats are supported.");
        setFile(null); // Clear the file state
      } else {
        setFile(selectedFile);

        setLogoUrl(selectedFile.name),
          // console.log(formData.LogoUrl)
          setError(null); // Clear any existing error
        // onFileSelected(selectedFile);
      }
    }
  };
  useEffect(() => {}, [LogoUrl]);
  const clearFile = () => {
    setFile(null);
    setLogoUrl(null);
    setError(null);
  };
  const clearFileEdit = () => {
    setLogoUrl(null);
    setError(null);
  };

  return (
    <div>
      <DialogBox
        title={"Confirmation"}
        content={"Would you like to publish and update?"}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <div className="mainContainer">
        <div class="dis-flex headTitle">
          <img src={leftArrow} alt="" onClick={onCancel} className="cursor" />
          <p>Edit Channel</p>
        </div>
        <form
          ref={formRef}
          onSubmit={(e) => handleApprovePublish(e)}
          className="m-t-24"
        >
          <div className="Content">
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Name"
                  name="ChannelName"
                  value={ChannelName}
                  onChange={(e) => setChannelName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="System Name"
                  name="SystemName"
                  value={SystemName}
                  onChange={(e) => setSystemName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Poid"
                  name="Poid"
                  value={Poid}
                  onChange={(e) => setPoid(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Language Name"
                  name="LanguageName"
                  value={LanguageName}
                  onChange={(e) => setLanguageName(e.target.value)}
                  required
                  inputProps={{ maxLength: 50 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Sms Code"
                  name="SmsCode"
                  value={SmsCode}
                  onChange={(e) => setSmsCode(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                  inputProps={{ maxLength: 20 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <MaterialUITextField
                  label="Code"
                  name="Code"
                  value={Code}
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                  inputProps={{ maxLength: 20 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="displayFlex m-b">
              <div className="example-container">
                <MaterialUITextField
                  label="Price"
                  name="Price"
                  value={Price}
                  onChange={(e) => setPrice(e.target.value)}
                  onKeyDown={handleKeyDown}
                  required
                  inputProps={{ maxLength: 6 }}
                  className="commonInput"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                        <InfoIcon className="Icon-style" />
                      </Tooltip>
                    ),
                  }}
                />
              </div>
              <div className="example-container">
                <div className="commonInput ">
                  <Checkbox
                    checked={ShowInAlacarte}
                    name="ShowInAlacarte"
                    onChange={(e) => setShowInAlacarte(e.target.checked)}
                    disabled={parseFloat(Price) === 0} 
                    sx={{
                      color: "#EE3823",
                      "&.Mui-checked": {
                        color: "#EE3823",
                      },
                    }}
                  />
                  Show in A-la-carte
                </div>
              </div>
              <div className="example-container">
                <div className="commonInput">
                  <Checkbox
                    checked={IsSundirectChannel}
                    name="IsSundirectChannel"
                    onChange={(e) => setIsSundirectChannel(e.target.checked)}
                    sx={{
                      color: "#EE3823",
                      "&.Mui-checked": {
                        color: "#EE3823",
                      },
                    }}
                  />
                  Is Sundirect Channel
                </div>
              </div>
            </div>

            {GenreArray.SdMGenre && GenreArray.SdMGenre.length > 0 && (
              <div className="exmple-container m-b">
                <div className="commonInput">
                  <Autocomplete
                    multiple
                    id="autocomplete-packages"
                    value={selectedPackages || []}
                    onChange={(event, value) => setSelectedPackages(value)}
                    options={GenreArray.SdMGenre.filter(
                      (option) =>
                        !(
                          selectedPackages &&
                          selectedPackages.find(
                            (selected) => selected.GenreId === option.Id
                          )
                        ) &&
                        !(selectedPackages || []).some(
                          (selected) => selected.GenreId === option.Id
                        )
                    )}
                    getOptionLabel={(option) => option.GenreName}
                    getOptionSelected={(option, value) =>
                      option.GenreId === value.GenreId
                    } // add this line
                    style={{ width: 858, marginBottom: 24 }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          label={option.GenreName}
                          {...getTagProps({ index })}
                          className="chipsStyle"
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <MuiTextField
                        {...params}
                        label="Genre"
                        variant="standard"
                      />
                    )}
                  />
                </div>
              </div>
            )}

            
            <div className="displayFlex">
            <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={ShowInFTA}
                name="ShowInFTA"
                onChange={(e) => setShowInFTA(e.target.checked)}

                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
             Show In FTA
              </div>
            </div>
            <div className="example-container">
            <div className="commonInput mt9">
              <Checkbox
                checked={isChecked}
                name="Is Checked"
                onChange={(e) => setIsChecked(e.target.checked)}

                sx={{
                  color: "#EE3823",
                  "&.Mui-checked": {
                    color: "#EE3823",
                  },
                }}
              />
             Is Checked
              </div>
            </div>
              <div className="example-container p-b-29">
              <div className="checkbox-lable m-24">
                Logo
                <span className="cursor imgInfoIcon">
                  <Tooltip title="Supported form-.jpg, .png. Max file supported-2MB">
                    <InfoIcon className="Icon-style svg-info " />
                  </Tooltip>
                </span>
              </div>
              <div className="disflex">
                <div>
                  <label htmlFor="fileInput" className="upload-file-label">
                    Choose Files
                  </label>
                  <input
                    className="upload-file"
                    type="file"
                    id="fileInput"
                    onChange={handleFileChange}
                  />
                </div>
                <span>
                  {file ? (
                    <a href="#" className="uploaded-file-link">
                      {file.name}
                    </a>
                  ) : (
                    <a href="#" className="uploaded-file-link">
                      {LogoUrl}
                    </a>
                  )}
                  {/* {file && <button onClick={clearFile} >&times;</button>} */}
                  {file ? (
                  
                      <CloseIcon  className="closeUpload cursor"
                      onClick={clearFile} />
                  ) : LogoUrl ? (
                   
                      <CloseIcon className="closeUpload cursor"
                      onClick={clearFileEdit} />
                  ) : null}
                </span>
              </div>
              {error && <div className="imgError">{error}</div>}
              <p className="imgInfo">
                [Recommended for this image pixel width(200) x height(150)]
              </p>
            </div>
           
            </div>
          
            {userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <div className="displayFlex m-b">
                   <div className="example-container">
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  <Select
                    className="commonInput"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                  >
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                  </Select>
                </FormControl>
              </div>
                    <div className="example-container">
                      <FormControl>
                        <InputLabel>Approval Status</InputLabel>
                        <Select
                          className="commonInput"
                          value={ChannelStatus}
                          onChange={(e) =>
                            onApprovalStatusChange(e.target.value)
                          }
                          required
                        >
                          {ApproveStatus.map((status) => (
                            <MenuItem key={status.value} value={status.value}>
                              {status.viewValue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <p className="imgInfo">[Select Approval to publish]</p>
                    </div>
                  <div className="example-container">
                      <MaterialUITextField
                        className="commonInput"
                        label="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  {ChannelStatus === "scheduled" && (
                    <div className="example-container">
                      <MaterialUITextField
                        label="Scheduled Publish Date"
                        type="date"
                        className="commonInput"
                        required
                        value={
                          scheduledPublishDt
                            ? scheduledPublishDt.split("T")[0]
                            : scheduledPublishDt
                        }
                        onChange={(e) => setScheduledPublishDt(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: new Date().toISOString().split("T")[0],
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

<div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Posted on</p>
                <p className="postDetail">
                  {formattedDate ? formattedDate : ''} By {editData ? editData.CreatedByName : ''}
                </p>
              </div>
            </div>
            <div className="example-container">
              <div className="commonInput m-24">
                <p className="post">Modified on</p>
                <p className="postDetail">
                  {ModifiedformattedDate ? ModifiedformattedDate : ''} By {editData ? editData.ModifiedByName : '-'}
                </p>
              </div>
            </div>
          </div>

          <div className="submitButton">
          {ChannelStatus === "approval" &&
              userDetails &&
              (userDetails.RoleId == 1 || userDetails.RoleId == 3) && (
                <Button
                  type="submit"
                  className="publishBtn"
                  onClick={(e) => {
                    if (formRef.current && formRef.current.checkValidity()) {
                      onPublish(e);
                    } else if (formRef.current) {
                      formRef.current.reportValidity();
                    }
                  }}
                >
                  Update &amp; Publish
                </Button>
              )}
            {ChannelStatus != "approval" && (
              <Button className="btn" type="submit">
                Update
              </Button>
            )}
            <Button className="cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChannelEdit;
