import React, { useState,useEffect } from 'react';
import leftArrow from './../../../../src/images/left-arrow.svg';
import { TextField, Button, Select, MenuItem, InputLabel,IconButton } from '@material-ui/core';
import { FormControl as MuiFormControl } from '@material-ui/core'; // Renamed MuiFormControl
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info'; 
import Tooltip from '@material-ui/core/Tooltip';
import ApiService from '../../../Services/ApiService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
const MenusAdd = ({ onCancel }) => {
  const [selectedValue, setSelectedValue] = React.useState('header');
  const [contentPage, setcontentPage] = useState(null)
  const [section, setsection] = useState(null)

  const fetchDataPage = async () => {
    const filterData = {
     "Index": 0,
     "Limit": 100000,
     "OrderByTable": "",
     "OrderByField": "",
     "Sort": "",
     "Aggregator": "",
     "Conditions": [
       
     ]
   }
         const response = ApiService.post('sdmcontentpages/list', filterData)
         .then(response => {
          setcontentPage(response.SdMContentpages)
             
         })
         .catch(error => {
           console.error('Error:', error);
           setLoading(false);
         });
        
       }
       const fetchDataSection = async () => {
        const filterData = {
         "Index": 0,
         "Limit": 100000,
         "OrderByTable": "",
         "OrderByField": "",
         "Sort": "",
         "Aggregator": "",
         "Conditions": [
           
         ]
       }
             const response = ApiService.post('sdmmenusection/list', filterData)
             .then(response => {
              setsection(response.SdMMenusection)
                 
             })
             .catch(error => {
               console.error('Error:', error);
               setLoading(false);
             });
            
           }
       useEffect(() => {

        fetchDataPage();
        fetchDataSection();
      }, []);
    const [formData, setFormData] = useState({
      MenuName:'',
      MenuType:'header',
      UrlPageId:'',
      MenuSectionId:'',
      OpenIn:'same window(_self)',
        IsActive: 'Active',
      });
  const [loading, setLoading] = useState(true);
    
      const statusOptions = [
        { value: 'Active', viewValue: 'Active' },
        { value: 'Inactive', viewValue: 'Inactive' },
      ];
      const OpeninOptions = [
        { value: 'same window(_self)', viewValue: 'Same Window(_self)' },
        { value: 'new window(_blank)', viewValue: 'New Window(_blank)' },
      ];
      const [userData, setUserData] = useState(null);
      useEffect(() => {
        const storedData = sessionStorage.getItem('userDetail');
    
        if (storedData) {
          const parsedData = JSON.parse(storedData);
    
          setUserData(parsedData);
        }
      }, []);
      const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'UrlPageId'){
          const urlPageById = contentPage.find((type) => type.Id === value);
          setFormData((prevData) => ({
            ...prevData,
            "SdMContentpages":{
              Id:urlPageById.Id,
              ContentTitle:urlPageById.ContentTitle
            }
          }));
          
        }
        if(name === 'MenuSectionId'){
          const MenuSectionById = section.find((type) => type.Id === value);
          setFormData((prevData) => ({
            ...prevData,
            "SdMMenusection":{
              Id:MenuSectionById.Id,
              SectionName:MenuSectionById.SectionName
            }
          }));
          
        }
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
      const handleTypeChange = (e) => {
        setSelectedValue(e.target.value);
        setFormData((prevData) => ({
          ...prevData,
          MenuType: e.target.value,
        }));
      };
      function getCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      }
      const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
        const updatedFormData = {
            ...formData,
            CreatedOn: getCurrentDateTime(),
            CreatedBy:userData.Id,
            IsActive:formData.IsActive == "Active" ? true : false,
          };
          setFormData(updatedFormData);
          try {
            const res = await ApiService.post("sdmmenus", updatedFormData);
            if (res) {
                handleCancel()
            }
          } catch (error) {
            console.error('Error updating toggle state:', error);
          }
        console.log('Form submitted:', updatedFormData);
      };
    
      const handleCancel = () => {
        // Handle cancel action
        onCancel();
      };
      const controlProps = (item) => ({
        onChange: handleTypeChange,
        value: item,
        name: 'MenuType',
        inputProps: { 'aria-label': item },
      });
      
  return (
    <div className='mainContainer'>
     <div class="dis-flex headTitle">
       <img src={leftArrow} alt="" onClick={handleCancel} className='cursor'/>
        <p>Create Menus</p>
    </div>
    <form onSubmit={handleSubmit} className='m-t-24'>
      <div className="Content">
        <div className="displayFlex m-b">
          <div className="example-container">
            <TextField
              label="Menu Name"
              name="MenuName"
              value={formData.MenuName}
              onChange={handleChange}
              required
              maxLength={50}
              className='commonInput'
              InputProps={{
                endAdornment: (
                    <Tooltip title="Supports alphabets, numbers and up to 50 characters.">
                    <InfoIcon className='Icon-style' />
                </Tooltip>
                ),
              }}
            />
          </div>
          <div className="example-container m-b">
  <div className="typeLabel">
    Type
  </div>
  <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        value={selectedValue} // Set the value to selectedValue
      >
        <FormControlLabel
          {...controlProps('header')}
          sx={{
            color: "#EE3823 !important'", // Change the color here
            '&.Mui-checked': {
              color: "#EE3823 !important'", // Change the color here for the checked state
            },
          }}
          value="header" control={<Radio />} label="Header" />
        <FormControlLabel
        {...controlProps('footer')}
        sx={{
          color: "#EE3823 !important'", // Change the color here
          '&.Mui-checked': {
            color: "#EE3823 !important'", // Change the color here for the checked state
          },
        }}
        value="footer" control={<Radio />} label="Footer" />
        <FormControlLabel
        {...controlProps('SideBar')}
        sx={{
          color: "#EE3823 !important'", // Change the color here
          '&.Mui-checked': {
            color: "#EE3823 !important'", // Change the color here for the checked state
          },
        }}
        value="SideBar" control={<Radio />} label="Side Bar" />
      </RadioGroup>
    </FormControl>
</div>
<div className="example-container">
            <MuiFormControl>
              <InputLabel>Url *</InputLabel>
              <Select
                name="UrlPageId"
                value={formData.UrlPageId}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {contentPage !== null ? (
      contentPage.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.ContentTitle}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </MuiFormControl>
          </div>
          
        </div>
        <div className="displayFlex m-b">
        <div className="example-container">
            <MuiFormControl>
              <InputLabel>Section *</InputLabel>
              <Select
                name="MenuSectionId"
                value={formData.MenuSectionId}
                onChange={handleChange}
                required
              className='commonInput'
              >
                {section !== null ? (
      section.map((type) => (
        <MenuItem key={type.Id} value={type.Id}>{type.SectionName}</MenuItem>
      ))
    ) : (
      <MenuItem value="">Loading...</MenuItem>
    )}
              </Select>
            </MuiFormControl>
          </div>
          <div className="example-container">
          <MuiFormControl >
            <InputLabel>Open in</InputLabel>
            <Select
              name="OpenIn"
              value={formData.OpenIn}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {OpeninOptions.map((open) => (
                <MenuItem key={open.value} value={open.value}>{open.viewValue}</MenuItem>
              ))}
            </Select>
          </MuiFormControl>
        </div>
        <div className="example-container">
          <MuiFormControl >
            <InputLabel>Status</InputLabel>
            <Select
              name="IsActive"
              value={formData.IsActive}
              onChange={handleChange}
              required
              className='commonInput'
            >
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>{status.viewValue}</MenuItem>
              ))}
            </Select>
          </MuiFormControl>
        </div>
        </div>
      
      </div>
      <div className="submitButton">
        <Button type="submit"  className="btn">Save</Button>
        <Button className="cancelBtn" onClick={handleCancel}>Cancel</Button>
      </div>
    </form>
    </div>
  );
};

export default MenusAdd;
